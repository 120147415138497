import React,{Component} from 'react'

const withAnimatedMount = (WrappedComponent) =>
    class AnimatedMount extends Component {

        render() {
            return <div
                className={"mount-animation-container"}
            >
                <WrappedComponent {...this.props}/>
            </div>
        }
    };

export default withAnimatedMount;

