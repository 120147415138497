import {LOGOUT, REQUEST_LOGIN, REQUEST_LOGIN_FULFILLED, REQUEST_LOGIN_REJECTED} from "./actionTypes";
import {getAuthInfo} from "./storeSession";

const initialState = {
    loading: false,
    loginResponse: getAuthInfo()
};

//{token:"unToken",rol: "unRol",permissions:[1]}

export default function reducer(state=initialState, action){

    switch (action.type){
        case REQUEST_LOGIN: return {...state,loading: true};
        case REQUEST_LOGIN_FULFILLED: return {...state,loading:false,loginResponse: action.payload};
        case REQUEST_LOGIN_REJECTED: return {...state,loading:false};

        case LOGOUT : return {loading: false,loginResponse: null};
       default: return state
    }
}