import { filters, modalFilters } from "../../../../model";
import Input from "../../../../../../common/input/index";
import { inputTypes } from "../../../../../../utils/types";
import React from "react";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import { strings } from "../../../../../../localization/strings";


class FilterDialogContent extends React.Component {
    state = { ...this.props.filters };

    render() {
        const { handleClose, asyncFilters, canFilterByAsesor } = this.props;

        const filteredModalFilters = modalFilters;

        const filterValues = this.state;
        return [
            <DialogContent key={"content"}>
                <form className={"custom-form"}>
                    {filteredModalFilters.map((filter, key) =>
                        <div key={key} className={filter.inputType === inputTypes.autosuggest ? "form-field-autosuggest-container" : "form-field-container"}>
                            <Input
                                onChange={(value) => this.handleFilterChange(filter.name, value)}
                                value={filterValues[filter.name] || ""}
                                type={filter.type}
                                inputType={filter.inputType}
                                label={filter.label}
                                loading={filter.async ? (asyncFilters && asyncFilters[filter.name].loading) : false}
                                options={(filter.inputType === inputTypes.select || filter.inputType === inputTypes.autosuggest) &&
                                    (filter.options || (asyncFilters && asyncFilters[filter.name].options))}
                            />
                        </div>
                    )}
                </form>
            </DialogContent>,
            <DialogActions key={"actions"}>
                <Button onClick={handleClose} color="primary">
                    {strings.cancel}
                </Button>
                <Button onClick={this.handleConfirmDialog} color="primary" autoFocus>
                    {strings.agree}
                </Button>
            </DialogActions>
        ]
    }

    handleFilterChange = (name, value) => this.setState({ [name]: value });
    handleConfirmDialog = () => {
        this.props.handleFilterChange(this.state);
        this.props.handleClose();
    }
}

export default FilterDialogContent
