import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio/Radio";

const Radios = ({ value, options, onChange, label, singleValue, direction, justify }) =>
    <React.Fragment>
        {label && <Typography variant={"subtitle1"}>{label}</Typography>}
        <ChecksContainer justify={justify} direction={direction}>
            {
                options.map(option =>
                    <FormControlLabel
                        key={option.value}
                        control={
                            <Radio
                                checked={value.includes(option.value)}
                                onChange={e => {
                                    if (value.includes(option.value))
                                        onChange(value.filter(item => item !== option.value));
                                    else {
                                        if (singleValue)
                                            onChange([option.value]);
                                        else
                                            onChange([...value, option.value])
                                    }
                                }}
                            />
                        }
                        label={option.label}
                    />
                )
            }
        </ChecksContainer>
    </React.Fragment>;

const ChecksContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.direction || "row"};
    justify-content: ${props => props.justify || "center"};
    
    min-width: 290px;
    .MuiTypography-root{
        font-size: 14px;
    }
`;

export default Radios;
