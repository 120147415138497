import {
    OPEN_TOAST,
    CLOSE_TOAST
} from "./actionTypes";

const initialState = {
    toastIsOpen: false,
    msg: null,
    title: null,
    actionClickHandler: null,
    cancelCallback: null,
    variant: null,
};

export default function reducer(state=initialState, action){

    switch (action.type){
        case OPEN_TOAST: {
            const {msg,actionClickHandler,failureCallback,variant} = action.payload;
            return {toastIsOpen:true,msg:msg,actionClickHandler:actionClickHandler,cancelCallback:failureCallback,variant};
        }
        case CLOSE_TOAST: return {...initialState,variant: state.variant};
        default: return state
    }
}