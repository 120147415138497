import React from "react"
import Event from "./event";
import ObservationsDialog from "./modal";
import {strings} from "../../../../localization/strings";


class Events extends React.Component{
    state={observationModalIsOpen:false};
    render() {
        const {observations,events,addObservation,loadingEvents,loadingObservations,canAddObservation} = this.props;
        const {observationModalIsOpen} = this.state;
        return [
            <Event key={"observations"} title={strings.observations} events={observations} loading={loadingObservations}
                   handleOpenEventModal={canAddObservation && this.handleOpenObservationModal}/>,
            <ObservationsDialog key={"observation-dialog"}
                                open={observationModalIsOpen}
                                addObservation={addObservation}
                                handleClose={this.handleCloseObservationModal}/>,
            <Event key={"events"} loading={loadingEvents} events={events} title={strings.events}/>
        ];
    }
    handleOpenObservationModal = () => this.setState({observationModalIsOpen: true});
    handleCloseObservationModal = () => this.setState({observationModalIsOpen: false});
}

export default Events