import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { getRoutes } from "./routes";
import withAuthWrapper from "./withAuthWrapper";
import withAnimatedMount from "../common/animated_mount/animatedMount";
// import Header from "../common/header";
import NavBar from "../common/menu";


const getRoutesPermissions = (permissions) => {
    const routes = getRoutes()
    const routesFiltradas = routes.filter(route => {
        return !route.permissions || route.permissions.some(permission => permissions.includes(parseInt(permission)));
    });

    return routesFiltradas
}
const Router = ({ loggedIn, onLogoutClick, permissions }) =>
    <BrowserRouter >
        <div id="app-container" >
            {/*<Header/>*/}
            <NavBar routes={getRoutesPermissions(permissions).filter(r => !!r.navTitle)}
                loggedIn={loggedIn}
                mainPath={"/"}
                // mainUserData={mainUserData}
                handleLogoutClick={onLogoutClick}
                main={
                    <Switch>
                        {getRoutesPermissions(permissions).map((route) =>
                            <Route key={route.path}
                                exact={!!route.isExact}
                                path={route.path + (route.params || "")}
                                component={
                                    route.requireAuth ?
                                        withAuthWrapper(loggedIn, withAnimatedMount(route.component)) :
                                        withAnimatedMount(route.component)
                                } />
                        )}
                    </Switch>
                }
            />

        </div>
    </BrowserRouter >;

export default Router
