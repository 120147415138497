import {inputTypes, types, valueIsOfType} from "../../utils/types";
import {errorStrings as errors} from "../../localization/errors";
import {strings} from "../../localization/strings";

export const MIN_PASSWORD_LENGTH = 2;
export const MAX_PASSWORD_LENGTH = 20;

export const emailValidation = (user) => {
    if(!user)
        return {msg: errors.enterEmail};

    if(!valueIsOfType(user,types.email))
        return {msg: errors.invalidUser};

    return true
};

export const passwordValidation = (password) => {
    if(!password)
        return {msg: errors.enterPassword};

    if(password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH)
        return {msg: errors.invalidPasswordLength};

    return true
};

export const inputs = [
    {
        name: "mail",
        validator: v => !!v,
        label: strings.userName,
        inputType: inputTypes.text,
        default: "business@business.com",
        type:"email"
    },
    {
        name: "contrasenia",
        validator: passwordValidation,
        label: strings.password,
        default: "next",
        inputType: inputTypes.text,
        type:"password",

    }
];
