import NAME from "./constants";
import { filters } from "./model";

export const isLoading = (store) => store[NAME].loadingAffiliations || store[NAME].exportingGrid;
export const getFilters = (store) => store[NAME].filters;
export const getAffiliations = (store) => store[NAME].affiliations[store[NAME].currentPage] || [];

export const getAllAsesores = (store) => store[NAME].asesores || [];

export const getTotalPages = (store) => store[NAME].totalPages;
export const getUserRole = (store) => store[NAME].userRole;
export const getRowsPerPage = (store) => store[NAME].rowsPerPage;
export const getCurrentPage = (store) => store[NAME].currentPage;


export const getAsyncFilters = store => ({
    [filters.state.name]: { loading: store[NAME].loadingAcceptedStates, options: store[NAME].acceptedStates },
    [filters.asesor.name]: { loading: store[NAME].loadingAcceptedStates, options: store[NAME].asesores },
    [filters.provincias.name]: { loading: store[NAME].loadingProvincias, options: store[NAME].provincias }
});