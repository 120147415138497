import moment from "moment";

export const types = {
    boolean: 0,
    number: 1,
    string: 2,
    date: 3,
    email: 4,
    password: 5,
    user: 6,
};

export const inputTypes = {
    boolean: 1,
    text: 2,
    select: 3,
    autoSuggest: 4,
    files: 5,
    yerOrNoSwitch: 6,
    shareLink: 7,
    editableField: 8,
    checks: 9,
    number: 10,
    datetime: 11,
    date: 12,
    asyncAutoSuggest: 14,
    norm: 15,
    multiselect: 16,
    textArray: 17,
    multipleSuggestionChip: 18,
    radio: 19,
    linkButton: 20,
    autosuggest: 21
};

// eslint-disable-next-line
const numberReg = /^\d+$/;
// eslint-disable-next-line
const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
const userReg = /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/;
// eslint-disable-next-line
//const passwordReg = /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/;
// eslint-disable-next-line
const passwordReg = /^(?=.{8,}$)(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])\S+$/;

export const valueIsOfType = (value,type) =>{
    if(!type)
        return false;

    switch(type){
        case types.number: return numberReg.test(value);
        case types.boolean: return (value === true || value === false);
        case types.string: return typeof value === 'string';
        case types.date: return moment(value).isValid();
        case types.email: return emailReg.test(value);
        case types.password: return passwordReg.test(value);
        case types.user: return userReg.test(value);
        default: return false
    }
};

export const validateVarchar = (value,len) => !value || value.length <= len;
