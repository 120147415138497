import React,{useState} from "react"
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import CustomTextField from "./text";

const EditableField = (props) => {
    const [editActive,setActive] = useState(false);
    return <div>
        <CustomTextField
            {...props}
            disabled={!editActive}
            InputProps={
                {
                    endAdornment:
                        <IconButton onClick={()=>setActive(!editActive)}>
                            {
                                editActive?
                                    <CheckIcon/>:
                                    <EditIcon />
                            }
                        </IconButton>
                }
            }
        />
    </div>
};

export default EditableField