import {applyMiddleware, createStore} from 'redux';
import reducers from "./combinedReducers";
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
//import {WEB_APP_ONLINE} from "../features/app/actionTypes";

const logger = createLogger({
    predicate: () => (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
});

const store =
    createStore(
        reducers,
        applyMiddleware(thunk,logger)
    );

// const listenToWindowEvent =
//     (name, mapEventToAction) =>
//         (dispatch) =>
//             window.addEventListener(name, (e)=> dispatch(mapEventToAction(e)));
//
// const navigatorOnLine = (e) => ({type: WEB_APP_ONLINE,payload: navigator.onLine});
//
// store.dispatch(listenToWindowEvent('load', navigatorOnLine));
// store.dispatch(listenToWindowEvent('offline', navigatorOnLine));
// store.dispatch(listenToWindowEvent('online', navigatorOnLine));

export default store;