import {
    CHANGE_FILTER,
    CHANGE_PAGE,
    CHANGE_ROWS_PER_PAGE,
    CREATE_ASESOR,
    CREATE_ASESOR_FULFILLED,
    CREATE_ASESOR_REJECTED, DELETE_ASESOR,
    DELETE_ASESOR_FULFILLED,
    DELETE_ASESOR_REJECTED,
    GET_AFFILIATIONS,
    GET_AFFILIATIONS_FULFILLED,
    GET_AFFILIATIONS_REJECTED,
    UPDATE_ASESOR,
    UPDATE_ASESOR_FULFILLED,
    UPDATE_ASESOR_REJECTED,
    GET_ASESORES,
    GET_ASESORES_FULFILLED,
    GET_ASESORES_REJECTED
} from "./actionTypes";
import NAME from "./constants";
import {
    createAsesor, deleteAsesor,
    getBlacklistAseores, updateAsesor,
    getAsesores
} from "../../shared/api";
import { orderProps } from "./model";

export const getAsesoresAction = (page, orderBy, orderDirection) => async (dispatch, getState) => {
    let store = getState()[NAME];

    dispatch({ type: GET_AFFILIATIONS });

    let filters = getState()[NAME].filters;

    getBlacklistAseores({ ...filters, pagina: page + 1, cantidad: store.rowsPerPage, orderBy: orderBy ? orderProps[orderBy] : undefined, orderDirection })
        .then((result) => dispatch({ type: GET_AFFILIATIONS_FULFILLED, payload: { page, affiliations: result.data, totalPages: result.data.TotalPaginas } }))
        .catch((e) => dispatch({ type: GET_AFFILIATIONS_REJECTED, payload: e }))
};

export const getAllAsesoresAction = async (dispatch, getState) => {
    if (getState()[NAME].asesores && getState()[NAME].asesores.length > 0)
        return;

    dispatch({ type: GET_ASESORES });
    //let filters = getState()[NAME].filters;
    getAsesores()
        .then((result) => dispatch({ type: GET_ASESORES_FULFILLED, payload: result.data.map(asesor => ({ value: asesor.Id, label: asesor.Id, name: asesor.Descripcion })) }))
        .catch((e) => dispatch({ type: GET_ASESORES_REJECTED, payload: e }))

};

export const updateAsesorAction = (data) => async dispatch => {
    dispatch({ type: UPDATE_ASESOR });
    try {
        const response = await updateAsesor(data);
        dispatch({ type: UPDATE_ASESOR_FULFILLED, payload: { ...data, createDialogOpen: true } });
    } catch (e) {
        dispatch({ type: UPDATE_ASESOR_REJECTED, payload: e })
    }
};

export const deleteAsesorAction = (data) => async dispatch => {
    dispatch({ type: DELETE_ASESOR });
    try {
        const response = await deleteAsesor(data);
        dispatch({ type: DELETE_ASESOR_FULFILLED, payload: response.data });
    } catch (e) {
        dispatch({ type: DELETE_ASESOR_REJECTED, payload: e })
    }
};

export const createAsesorAction = (data) => async dispatch => {
    dispatch({ type: CREATE_ASESOR });

    try {
        const response = await createAsesor(data);
        dispatch({ type: CREATE_ASESOR_FULFILLED, payload: response.data });
    } catch (e) {
        dispatch({ type: CREATE_ASESOR_REJECTED, payload: e })
    }
};


export const changeFilterAction = (newValues) => dispatch => dispatch({ type: CHANGE_FILTER, payload: newValues });
//export const changePageAction = (page) => dispatch => dispatch({type:CHANGE_PAGE,payload: page});
export const changeRowsPerPageAction = (rows) => dispatch => dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rows });
