import {MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH} from "../features/login/model";

export const errorStrings = {
    observationTypeError: "Elegí un tipo de observación",
    observationError: "Escribí un mensaje",

    invalidUser: `El email ingresado tiene caracteres que no están permitidos`,
    invalidPasswordLength: `La contraseña debe tener entre ${MIN_PASSWORD_LENGTH} y ${MAX_PASSWORD_LENGTH} caracteres`,
    invalidPassword: `La contraseña debe contener al menos 1 caracter en mayus y 1 caracter en minus`,
    passwordDontMatch: "Las contraseñas no coinciden",
    enterEmail: "Ingesá tu email",
    enterPassword: "Ingresá tu contraseña",
    enterDni: "Ingresá tu dni",
    invalidDni: "Dni inválido",
    invalidDniLength: "Dni inválido",
    enterPhone: "Ingresá tu teléfono",
    invalidPhone: "Teléfono inválido",
    invalidPhoneLength: "Teléfono inválido",

    defaultErrorTitle: "Oops..",
    defaultErrorMessage: "Ocurrió un problema, intentá nuevamente más tarde",
    expiredSession: "Tu sesión expiró, por favor volvé a ingresar tus datos para continuar"
};
