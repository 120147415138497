import NAME from "./constants";

export const GET_AFFILIATIONS = NAME + "/GET_AFFILIATIONS";
export const GET_AFFILIATIONS_FULFILLED = NAME + "/GET_AFFILIATIONS_FULFILLED";
export const GET_AFFILIATIONS_REJECTED = NAME + "/GET_AFFILIATIONS_REJECTED";

export const GET_STATES = NAME + "/GET_STATES";
export const GET_STATES_FULFILLED = NAME + "/GET_STATES_FULFILLED";
export const GET_STATES_REJECTED = NAME + "/GET_STATES_REJECTED";

export const GET_ASESORES = NAME + "/GET_ASESORES";
export const GET_ASESORES_FULFILLED = NAME + "/GET_ASESORES_FULFILLED";
export const GET_ASESORES_REJECTED = NAME + "/GET_ASESORES_REJECTED";

export const GET_PROVINCIAS = NAME + "/GET_PROVINCIAS";
export const GET_PROVINCIAS_FULFILLED = NAME + "/GET_PROVINCIAS_FULFILLED";
export const GET_PROVINCIAS_REJECTED = NAME + "/GET_PROVINCIAS_REJECTED";

export const EXPORT_GRID = NAME + "/EXPORT_GRID";
export const EXPORT_GRID_END = NAME + "/EXPORT_GRID_END";

export const CHANGE_FILTER = NAME + "/CHANGE_FILTER";
export const CHANGE_PAGE = NAME + "/CHANGE_PAGE";
export const CHANGE_ROWS_PER_PAGE = NAME + "/CHANGE_ROWS_PER_PAGE";

export const CREATE_ASESOR = NAME + "/CREATE_ASESOR";
export const CREATE_ASESOR_FULFILLED = NAME + "/CREATE_ASESOR_FULFILLED";
export const CREATE_ASESOR_REJECTED = NAME + "/CREATE_ASESOR_REJECTED";

export const UPDATE_ASESOR = NAME + "/UPDATE_ASESOR";
export const UPDATE_ASESOR_FULFILLED = NAME + "/UPDATE_ASESOR_FULFILLED";
export const UPDATE_ASESOR_REJECTED = NAME + "/UPDATE_ASESOR_REJECTED";

export const DELETE_ASESOR = NAME + "/DELETE_ASESOR";
export const DELETE_ASESOR_FULFILLED = NAME + "/DELETE_ASESOR_FULFILLED";
export const DELETE_ASESOR_REJECTED = NAME + "/DELETE_ASESOR_REJECTED";
