import {
    CHANGE_FILTER,
    CHANGE_PAGE,
    CHANGE_ROWS_PER_PAGE,
    EXPORT_GRID,
    EXPORT_GRID_END,
    GET_AFFILIATIONS,
    GET_AFFILIATIONS_FULFILLED,
    GET_AFFILIATIONS_REJECTED,
} from "./actionTypes";
import NAME from "./constants";
import {
    exportAportes,
    getAportes
} from "../../shared/api";

export const getAportesAction = (page) => async (dispatch, getState) => {

    let store = getState()[NAME];
    if (store.affiliations[page]) {
        dispatch({ type: CHANGE_PAGE, payload: page });
        return;
    }

    dispatch({ type: GET_AFFILIATIONS });

    let filters = getState()[NAME].filters;

    getAportes({ ...filters, provincia: filters.provincias && filters.provincias.value, pagina: page, cantidad: store.rowsPerPage })
        .then((result) => dispatch({ type: GET_AFFILIATIONS_FULFILLED, payload: { page, affiliations: result.data.Resultados, totalPages: result.data.TotalPaginas } }))
        .catch((e) => dispatch({ type: GET_AFFILIATIONS_REJECTED, payload: e }))

};

export const exportGridAction = async (dispatch, getState) => {
    dispatch({ type: EXPORT_GRID });
    let filters = getState()[NAME].filters;
    let store = getState()[NAME];

    exportAportes({ ...filters, provincia: filters.provincias && filters.provincias.value, cantidad: store.rowsPerPage })
        .then((result) => {
            dispatch({ type: EXPORT_GRID_END, payload: result.data });
            //const url = window.URL.createObjectURL();
            const blob = new Blob([result.data], { type: "application/vnd.ms-excel" });
            const filename = "aportes.xlsx";

            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        })
        .catch((e) => dispatch({ type: EXPORT_GRID_END, payload: e }))

};

export const changeFilterAction = (newValues) => dispatch => dispatch({ type: CHANGE_FILTER, payload: newValues });
//export const changePageAction = (page) => dispatch => dispatch({type:CHANGE_PAGE,payload: page});
export const changeRowsPerPageAction = (rows) => dispatch => dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rows });
