import styled from "styled-components";
import Typography from "@material-ui/core/Typography/Typography";
import {colors} from "../../config/colors";
const DEFAULT_FONT_PX_SIZE = 14;
const FormTitle = styled(Typography)`
    &.MuiTypography-root{
        font-weight: bold;    
        font-size: ${props=> props.size||DEFAULT_FONT_PX_SIZE}px;
    }
    max-width:100%;
    color: ${props => props.color || colors.titlesColor}
`;

export default FormTitle
