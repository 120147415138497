import React from "react"
import CustomTextField from "./text";
import CustomSelect from "./select";
import { inputTypes } from "../../utils/types";
import CustomBooleanInput from "./boolean";
import CustomFiles from "./files";
import YesOrNoSwitch from "./yesOrNoSwitch";
import ShareLink from "./shareLink";
import EditableField from "./editableField";
import Checks from "./checks";
import CustomNorms from "./norm";
import CustomTextArray from "./textArray";
import MultipleSuggestion from "./multipleSuggestion";
import Radio from "./radio";
//import IntegrationDownshift from "./autosuggest";
import AutoSuggest from "./autosuggest";

const Input = (props) => {
    switch (props.inputType) {
        case inputTypes.color: return <CustomSelect {...props} />;
        case inputTypes.select: return <CustomSelect {...props} />;

        case inputTypes.files: return <CustomFiles {...props} />;
        case inputTypes.boolean: return <CustomBooleanInput {...props} />;
        case inputTypes.yerOrNoSwitch: return <YesOrNoSwitch {...props} />;
        case inputTypes.shareLink: return <ShareLink {...props} />;
        case inputTypes.editableField: return <EditableField {...props} />;
        case inputTypes.checks: return <Checks {...props} />;
        case inputTypes.autosuggest: return <AutoSuggest {...props} />;
        case inputTypes.radio: return <Radio {...props} />;
        case inputTypes.norm: return <CustomNorms {...props} />;
        case inputTypes.multiselect: return <CustomSelect {...props} multiple={true} />;
        case inputTypes.textArray: return <CustomTextArray {...props} />;
        case inputTypes.multipleSuggestionChip: return <MultipleSuggestion {...props} />;
        case inputTypes.date:
        case inputTypes.text:
        case inputTypes.datetime:
        case inputTypes.number:
        default: return <CustomTextField {...props} />;
    }
};

export default Input
