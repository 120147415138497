import { strings } from "../../localization/strings";
import { inputTypes } from "../../utils/types";
import moment from "moment";
import { constantStrings, regulationStatusConstantStrings } from "../../shared/constantStrings";
import { regulationStatus } from "../../shared/constants";
import React from "react";


export const orderProps = {
    Nombre: "nombre",
    codAsesor: "codasesor",
    Tipo: "tipo",
    Registro: "registro",
    Estado: "estado",
    Expiracion: "expiracion"
};

export const columns = [

    { title: strings.fullname, fieldProp: "Nombre", index: 0, order: true },
    { title: strings.codAsesor, order: true, inputType: inputTypes.text, type: "number", fieldProp: "codAsesor", index: 1 },
    { title: strings.type, order: true, fieldProp: "Tipo", index: 2 },
    { title: strings.register, order: true, fieldProp: "Registro", format: v => moment(v).format("DD/MM/YYYY"), index: 3 },
    { title: strings.expiration, order: true, inputType: inputTypes.text, type: "date", fieldProp: "Expiracion", format: v => moment(v).format("DD/MM/YYYY"), index: 4 },
    { title: strings.state, order: true, fieldProp: "Estado", index: 5, format: (v) => regulationStatusConstantStrings[v] },
];

export const filters = {
    quantity: { name: "cantidad", type: inputTypes.number, label: strings.quantity },
    dni: { name: "dni", type: inputTypes.text, label: strings.dni },
    state: { name: "estado", type: inputTypes.select, async: true, label: strings.state },
    provincias: { name: "provincias", type: inputTypes.autosuggest, async: true, label: strings.provincias },
    startDate: { name: "fechadesde", type: inputTypes.date, label: strings.startDate },
    endDate: { name: "fechaHasta", type: inputTypes.date, label: strings.endDate },
    invalidoComercial: { name: "invalidoComercial", type: inputTypes.boolean, label: strings.invalidoComercial },
    invalidoMedico: { name: "invalidoMedico", type: inputTypes.boolean, label: strings.invalidoMedico },
    mail: { name: "mail", type: inputTypes.mail, label: strings.mail },
    page: { name: "pagina", type: inputTypes.text, label: strings.page },
    userRole: { name: "rolusuario", type: inputTypes.number, label: strings.userRole },
    asesor: { name: "asesor", type: inputTypes.autosuggest, async: true, label: strings.asesor },
    smart: { name: "smart", type: inputTypes.boolean, label: strings.smartPlan },
    regulationStatus: {
        name: "estadodesregulacion", type: inputTypes.select, options: [
            { value: -1, label: strings.all },
            { value: regulationStatus.expired, label: regulationStatusConstantStrings[regulationStatus.expired] },
            { value: regulationStatus.done, label: regulationStatusConstantStrings[regulationStatus.done] },
            { value: regulationStatus.pending, label: regulationStatusConstantStrings[regulationStatus.pending] },
        ], label: strings.regulationStatus
    }
};

export const modalFilters = [
    filters.dni,
    //filters.mail,
    filters.startDate,
    filters.endDate,
    filters.state,
    //filters.regulationStatus,
    filters.smart,
    filters.asesor,
    filters.provincias
];
