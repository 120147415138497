import {
    CHANGE_FILTER,
    CHANGE_PAGE,
    CHANGE_ROWS_PER_PAGE,
    EXPORT_GRID,
    EXPORT_GRID_END,
    GET_AFFILIATIONS,
    GET_AFFILIATIONS_FULFILLED,
    GET_AFFILIATIONS_REJECTED,
    RESET_APORTES
} from "./actionTypes";
import { ROWS_PER_PAGE_DEFAULT_OPTION } from "../../config";

const initialState = {
    userRole: 1,

    affiliations: {},
    loadingAffiliations: false,

    acceptedStates: [],
    loadingAcceptedStates: false,

    asesores: [],
    loadingAsesores: false,

    provincias: [],
    loadingProvincias: false,

    filters: {
        //cantidad: 8,
        //dni: "dni",
        //estado: "11",
        hasta: undefined,
        desde: undefined,
        dni: undefined,
        //mail: "fran@gm.com",
        pagina: "1",

    },
    totalPages: 1,
    currentPage: 1,
    rowsPerPage: ROWS_PER_PAGE_DEFAULT_OPTION,
    exportingGrid: false

};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case GET_AFFILIATIONS: return { ...state, loadingAffiliations: true };
        case GET_AFFILIATIONS_FULFILLED: return { ...state, loadingAffiliations: false, affiliations: { ...state.affiliations, [action.payload.page]: action.payload.affiliations }, currentPage: action.payload.page, totalPages: action.payload.totalPages };
        case GET_AFFILIATIONS_REJECTED: return { ...state, loadingAffiliations: false };

        case RESET_APORTES: return { ...initialState };

        case EXPORT_GRID: return { ...state, exportingGrid: true };
        case EXPORT_GRID_END: return { ...state, exportingGrid: false };

        case CHANGE_FILTER: return { ...state, filters: { ...state.filters, ...action.payload }, affiliations: {} };
        case CHANGE_PAGE: return { ...state, currentPage: action.payload };
        case CHANGE_ROWS_PER_PAGE: return { ...state, rowsPerPage: action.payload };
        default: return state
    }
}
