import {
    CHANGE_FILTER,
    CHANGE_PAGE,
    CHANGE_ROWS_PER_PAGE,
    EXPORT_GRID,
    EXPORT_GRID_END,
    GET_AFFILIATIONS,
    GET_AFFILIATIONS_FULFILLED,
    GET_AFFILIATIONS_REJECTED,
    GET_ASESORES,
    GET_ASESORES_FULFILLED,
    GET_ASESORES_REJECTED,
    GET_PROVINCIAS,
    GET_PROVINCIAS_FULFILLED, GET_PROVINCIAS_REJECTED,
    GET_STATES,
    GET_STATES_FULFILLED,
    GET_STATES_REJECTED,
    RESET_AFFILIATIONS
} from "./actionTypes";
import { ROWS_PER_PAGE_DEFAULT_OPTION } from "../../config";
import moment from "moment";

const initialState = {
    userRole: 1,

    affiliations: {},
    loadingAffiliations: false,

    acceptedStates: [],
    loadingAcceptedStates: false,

    asesores: [],
    loadingAsesores: false,

    provincias: [],
    loadingProvincias: false,

    filters: {
        //cantidad: 8,
        //dni: "dni",
        //estado: "11",
        fechaHasta: null,
        fechadesde: null,
        invalidoComercial: false,
        invalidoMedico: true,
        //mail: "fran@gm.com",
        pagina: "1",
        rolusuario: 47,
        smart: false,
    },
    totalPages: 1,
    currentPage: 1,
    rowsPerPage: ROWS_PER_PAGE_DEFAULT_OPTION,
    exportingGrid: false

};

export default function reducer(state = initialState, action) {

    switch (action.type) {
        case GET_AFFILIATIONS: return { ...state, loadingAffiliations: true };
        case GET_AFFILIATIONS_FULFILLED: return { ...state, loadingAffiliations: false, affiliations: { ...state.affiliations, [action.payload.page]: action.payload.affiliations }, currentPage: action.payload.page, totalPages: action.payload.totalPages };
        case GET_AFFILIATIONS_REJECTED: return { ...state, loadingAffiliations: false };

        case GET_STATES: return { ...state, loadingAcceptedStates: true };
        case GET_STATES_FULFILLED: return { ...state, loadingAcceptedStates: false, acceptedStates: action.payload };
        case GET_STATES_REJECTED: return { ...state, loadingAcceptedStates: false };

        case GET_PROVINCIAS: return { ...state, loadingProvincias: true };
        case GET_PROVINCIAS_FULFILLED: return { ...state, loadingProvincias: false, provincias: action.payload };
        case GET_PROVINCIAS_REJECTED: return { ...state, loadingProvincias: false };

        case GET_ASESORES: return { ...state, loadingAsesores: true };
        case GET_ASESORES_FULFILLED: return { ...state, loadingAsesores: false, asesores: action.payload };
        case GET_ASESORES_REJECTED: return { ...state, loadingAsesores: false };

        case RESET_AFFILIATIONS: return { ...initialState };

        case EXPORT_GRID: return { ...state, exportingGrid: true };
        case EXPORT_GRID_END: return { ...state, exportingGrid: false };

        case CHANGE_FILTER: return { ...state, filters: { ...state.filters, ...action.payload }, affiliations: {} };
        case CHANGE_PAGE: return { ...state, currentPage: action.payload };
        case CHANGE_ROWS_PER_PAGE: return { ...state, rowsPerPage: action.payload };
        default: return state
    }
}
