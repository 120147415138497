import React, { useState } from "react";
import CustomTable from "./table/table";
import {
    changeFilterAction,
    changeRowsPerPageAction, createAsesorAction, deleteAsesorAction,
    getAsesoresAction, getAllAsesoresAction,
    getStatesAction, updateAsesorAction
} from "../actions";
import { connect } from "react-redux";
import Loader from "../../../common/loader";
import {
    getAffiliations, getAsyncFilters, getCurrentPage,
    getFilters, getRowsPerPage,
    getTotalPages,
    getUserRole,
    isLoading,
    getAllAsesores
} from "../selectors";
import { createStructuredSelector } from "reselect";
import Filter from "./table/options";
import { routes } from "../../../router/routes";
import { ROWS_PER_PAGE_DEFAULT_OPTION } from "../../../config";
import { hasPermission } from "../../login/selectors";
import { permissions } from "../../../shared/permissions";

class AsesorBlacklistPage extends React.Component {
    state = { orderBy: "", orderDirection: "" };
    state = { createDialogOpen: false };

    componentDidMount() {
        this.props.dispatch(getAsesoresAction(0));
        this.props.dispatch(getAllAsesoresAction)
    }

    render() {
        const { loading, filters, affiliations, totalPages, allAsesores,
            //userRole,
            currentPage, rowsPerPage, asyncFilters, canFilterByAsesor } = this.props;
        const { orderDirection, orderBy } = this.state;
        return <div id={"main-page"}>
            <Loader loading={loading} />
            <Filter
                handleFilterChange={this.handleFilterChange}
                filters={filters}
                canFilterByAsesor={canFilterByAsesor}
                asyncFilters={asyncFilters}
                handleCreateDialogOpen={this.handleCreateDialogOpen}
            // handleDownloadExcelClick={this.handleDownloadExcelClick}
            />
            <CustomTable
                rows={affiliations}
                // handleDetailClick={this.handleDetailClick}
                orderBy={orderBy}
                orderDirection={orderDirection}
                onOrderClick={this.onOrderClick}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                allAsesores={allAsesores}
                totalPages={totalPages}
                createDialogOpenAdd={this.state.createDialogOpen}
                handleCreateDialogOpen={(dialogOpen) => this.handleCreateDialogOpen(dialogOpen)}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                onDelete={(d) => this.props.dispatch(deleteAsesorAction(d)).then(() => this.props.dispatch(getAsesoresAction(0)))}
                onUpdate={(d) => this.props.dispatch(updateAsesorAction(d)).then(() => this.props.dispatch(getAsesoresAction(0)))}
                onCreate={(d) => this.props.dispatch(createAsesorAction(d)).then(() => this.props.dispatch(getAsesoresAction(0)))}
            />
        </div>
    };

    handleCreateDialogOpen = (dialogOpen) => {
        this.setState({ createDialogOpen: dialogOpen });
    }

    onOrderClick = (orderBy, orderDirection) => {
        this.setState({ orderBy, orderDirection });
        this.props.dispatch(getAsesoresAction(0, orderBy, orderDirection));
    };

    handleFilterChange = (newValues) => {
        this.props.dispatch(changeFilterAction(newValues));
        this.props.dispatch(getAsesoresAction(0));
    };
    handleDetailClick = (guid) => this.props.history.push(routes.details.path + "/" + guid);
    handleChangePage = (e, newPage) => {
        if (!e)
            return;
        let page = 1;
        try {
            page = newPage;
            //page = parseInt(newPage,10);
        } catch (e) {
            //Si por alguna razon material me devuelve algo que no puede parsearse a int vuelvo a la primera página
            page = 1;
        }
        this.props.dispatch(getAsesoresAction(page));
    };
    handleChangeRowsPerPage = (e) => {
        try {
            this.props.dispatch(changeRowsPerPageAction(parseInt(e.target.value)));
        } catch (e) {
            //Si por alguna razon material me devuelve algo que no puede parsearse a int muestro los default
            this.props.dispatch(changeRowsPerPageAction(ROWS_PER_PAGE_DEFAULT_OPTION));
        }
    };

}

export default connect(createStructuredSelector({
    loading: isLoading,
    filters: getFilters,
    affiliations: getAffiliations,
    totalPages: getTotalPages,
    userRole: getUserRole,
    asyncFilters: getAsyncFilters,
    allAsesores: getAllAsesores,
    rowsPerPage: getRowsPerPage,
    currentPage: getCurrentPage,
    canFilterByAsesor: hasPermission(permissions.asesoresFilter)
}))(AsesorBlacklistPage)
