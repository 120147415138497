import React from "react";
import CircleProgress from "@material-ui/core/CircularProgress";

const Loader = ({loading}) =>
    loading ?
    <div id={"full-screen-loader"}>
        <div id={"loader-center-container"}>
            <CircleProgress/>
        </div>
    </div>:"";

export default Loader;