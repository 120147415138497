import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CustomBooleanInput = ({value,type,label,onChange,valid,showState,variant}) =>
    <FormControlLabel
        control={
            <Checkbox
                checked={!!value}
                onChange={e=>onChange(e.target.checked)}
            />
        }
        label={label}
    />;

export default CustomBooleanInput
