import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {colors} from "../../config/colors";

const FormButton = ({loading,label,disabled,color,onClick,fullWidth,variant}) =>
    <CustomButton
        disabled={disabled}
        onClick={onClick}
        fullWidth={!!fullWidth}
        variant={variant}
        color={color || "primary"}>
        {
            loading ?
                <CustomCircularProgress/>:
                label
        }
    </CustomButton>;

const CustomCircularProgress = styled(CircularProgress)`
    height:30px;
    width:30px;
`;

const CustomButton = styled(Button)`
    &.MuiButton-containedPrimary{
        margin: 5px 0;
        min-width: 240px;
        height: 40px;
    }
    color: white;   
    height: 50px;
`;

export default FormButton
