import NAME from "./constants";
import {affiliationFields, personalInfoFields} from "./model";
import {hasPermission} from "../login/selectors";
import {permissions} from "../../shared/permissions";

export const isLoadingProfileStates = (store) => store[NAME].loadingProfileStates || store[NAME].changingPlanState;
export const isLoadingProfile = (store) => store[NAME].loadingProfile || store[NAME].changingPlanState ;
export const isLoadingObservations = (store) => store[NAME].loadingObservations;
export const isLoadingEvents = (store) => store[NAME].loadingEvents;

export const getPersonalInfo = (store) => {
    let info = {};

    if(!store[NAME].beneficiarioIndex || store[NAME].beneficiarioIndex === 0) //InfoPersonal del usuario
        info = (store[NAME].profile || {}).infoPersonal || {};
    else
        info = (store[NAME].profile || {}).infoPersonal[personalInfoFields.beneficiarios.prop][store[NAME].beneficiarioIndex - 1] || {};
    if(!hasPermission(permissions.dni)(store))
        info[personalInfoFields.dni.prop] && delete info[personalInfoFields.dni.prop];

    return info
};

export const getAffiliation = store => {
    let afiliacion = (store[NAME].profile || {}).afiliacion || {};

    if(!hasPermission(permissions.marcaEspecial)(store))
        afiliacion[affiliationFields.marcaEspecial.prop] && delete afiliacion[affiliationFields.marcaEspecial.prop];

    return afiliacion
};

export const getDjs = (store) => {

    if(!hasPermission(permissions.djs)(store))
        return {};

    if(store[NAME].beneficiarioIndex === 0) //InfoPersonal del que se busca
        return (store[NAME].profile || {}).djs;

    return (store[NAME].profile || {}).djsBeneficiarios && (store[NAME].profile || {}).djsBeneficiarios[store[NAME].beneficiarioIndex-1]
};

export const getBeneficiarioIndex = store => store[NAME].beneficiarioIndex || 0;

export const getPlan = store => (store[NAME].profile || {}).infoPersonal && (store[NAME].profile || {}).infoPersonal[personalInfoFields.plan.prop];

export const getBeneficiarios = store => store[NAME].profile && store[NAME].profile.infoPersonal && [store[NAME].profile.infoPersonal ,...store[NAME].profile.infoPersonal[personalInfoFields.beneficiarios.prop]];

export const getObservations = store => {
    if(!hasPermission(permissions.observations)(store))
        return [];

    return store[NAME].observations
};

export const getEvents = store => {
    if(!hasPermission(permissions.events)(store))
        return [];

    return store[NAME].events
};

export const getProfileStates = store => store[NAME].profileStates;
