import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import {connect} from "react-redux";
import {isOnline} from "../selectors";
import { createStructuredSelector } from 'reselect';

// #5776ad

const onlineColors = {  "palette":{
                            "common":{"black":"#000","white":"#fff"},
                            "background":{"paper":"#fff","default":"#fafafa"},
                            "primary":{"light":"#494f83","main":"#1b2756","dark":"#00002d","contrastText":"#fff"},
                            "secondary":{"light":"#ff4081","main":"rgba(0, 0, 0, 1)","dark":"#c51162","contrastText":"#fff"},
                            "error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},
                            "text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)", "hint":"rgba(0, 0, 0, 0.38)"}
                        },
                        typography: {
                            useNextVariants: true,
                        }
                    };
const offlineColors = {"palette":{"common":{"black":"#000","white":"#fff"},"background":{"paper":"#fff","default":"#fafafa"},"primary":{"light":"#7986cb","main":"rgba(176, 176, 169, 1)","dark":"#303f9f","contrastText":"#fff"},"secondary":{"light":"#ff4081","main":"rgba(176, 176, 169, 1)","dark":"#c51162","contrastText":"#fff"},"error":{"light":"#e57373","main":"#f44336","dark":"#d32f2f","contrastText":"#fff"},"text":{"primary":"rgba(0, 0, 0, 0.87)","secondary":"rgba(0, 0, 0, 0.54)","disabled":"rgba(0, 0, 0, 0.38)","hint":"rgba(0, 0, 0, 0.38)"}}};

const onlineTheme = createMuiTheme(onlineColors);

const offlineTheme = createMuiTheme(offlineColors);

const Theme = ({children,isOnline}) =>
        <MuiThemeProvider theme={isOnline ? onlineTheme:offlineTheme}>
            {children}
        </MuiThemeProvider>;

export default connect(createStructuredSelector({
    isOnline: isOnline
}))(Theme)