import React from "react";
import green from '@material-ui/core/colors/green';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const NAME = "Toast";

export const variantStyles = {
    success: {
        content: {backgroundColor: green[600]},
        icon: <CheckCircleIcon/>
    },
    failure:{
        content: {backgroundColor:  "#d32f2f"},
        icon: <ErrorIcon/>
    }
};

export default NAME;