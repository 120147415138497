import {
    CHANGE_FILTER,
    CHANGE_PAGE,
    CHANGE_ROWS_PER_PAGE,
    EXPORT_GRID,
    EXPORT_GRID_END,
    GET_AFFILIATIONS,
    GET_AFFILIATIONS_FULFILLED,
    GET_AFFILIATIONS_REJECTED,
    GET_ASESORES,
    GET_ASESORES_FULFILLED, GET_ASESORES_REJECTED,
    GET_PROVINCIAS,
    GET_PROVINCIAS_FULFILLED,
    GET_PROVINCIAS_REJECTED,
    GET_STATES,
    GET_STATES_FULFILLED,
    GET_STATES_REJECTED
} from "./actionTypes";
import axios from "axios";
import NAME from "./constants";
import { exportGrid, getAffiliations, getAsesores, getProvincias, getStates } from "../../shared/api";


export const getAffiliationsAction = (page, orderBy, orderDirection) => async (dispatch, getState) => {

    let store = getState()[NAME];

    if (store.affiliations[page] && !orderBy && !orderDirection) {
        dispatch({ type: CHANGE_PAGE, payload: page });
        return;
    }

    dispatch({ type: GET_AFFILIATIONS });

    let filters = getState()[NAME].filters;

    getAffiliations({ ...filters, asesor: filters.asesor && filters.asesor.value, provincia: filters.provincias && filters.provincias.value, pagina: page + 1, cantidad: store.rowsPerPage, orderBy, direccion: orderDirection })
        .then((result) => dispatch({ type: GET_AFFILIATIONS_FULFILLED, payload: { page, affiliations: result.data.Afiliaciones, totalPages: result.data.TotalPaginas } }))
        .catch((e) => dispatch({ type: GET_AFFILIATIONS_REJECTED, payload: e }))

    //dispatch({type: GET_AFFILIATIONS_FULFILLED,payload: {page,affiliations: mock.Afiliaciones,totalPages: mock.TotalPaginas}});
};


//let mockStates = [{"Id":1,"Descripcion":"Deuda Pendiente De Pago"},{"Id":4,"Descripcion":"Pendiente"},{"Id":5,"Descripcion":"No LeInteresa"},{"Id":6,"Descripcion":"En Gestion"},{"Id":8,"Descripcion":"En Analisis"},{"Id":9,"Descripcion":"Error De Proceso"},{"Id":10,"Descripcion":"Proceso Incompleto"},{"Id":11,"Descripcion":"Datos Incompleto"},{"Id":12,"Descripcion":"Descartado"},{"Id":13,"Descripcion":"Error p/Area Médica"},{"Id":14,"Descripcion":"Error p/Area Comercial"}];
export const getStatesAction = async (dispatch, getState) => {
    if (getState()[NAME].acceptedStates && getState()[NAME].acceptedStates.length > 0)
        return;

    dispatch({ type: GET_STATES });
    getStates()
        .then((result) => dispatch({ type: GET_STATES_FULFILLED, payload: result.data.map(state => ({ value: state.Id, label: state.Descripcion })) }))
        .catch((e) => dispatch({ type: GET_STATES_REJECTED, payload: e }))

};

//let mockProvincias = [{"Id":2,"Descripcion":"San Luis"},{"Id":3,"Descripcion":"Cba"},{"Id":4,"Descripcion":"BsAs"}];
export const getProvinciasAction = async (dispatch, getState) => {
    if (getState()[NAME].provincias && getState()[NAME].provincias.length > 0)
        return;

    dispatch({ type: GET_PROVINCIAS });
    getProvincias()
        .then((result) => dispatch({ type: GET_PROVINCIAS_FULFILLED, payload: result.data.map(state => ({ value: state.Id, label: state.Descripcion })) }))
        .catch((e) => dispatch({ type: GET_PROVINCIAS_REJECTED, payload: e }))

};

//let mockAsesores = [{Id:"1",Descripcion:"1"},{Id:"2",Descripcion:"2"},{Id:"3",Descripcion:"3"}];
export const getAsesoresAction = async (dispatch, getState) => {
    if (getState()[NAME].asesores && getState()[NAME].asesores.length > 0)
        return;

    dispatch({ type: GET_ASESORES });
    //let filters = getState()[NAME].filters;
    getAsesores()
        .then((result) => dispatch({ type: GET_ASESORES_FULFILLED, payload: result.data.map(asesor => ({ value: asesor.Id, label: asesor.Descripcion })) }))
        .catch((e) => dispatch({ type: GET_ASESORES_REJECTED, payload: e }))

};

export const exportGridAction = async (dispatch, getState) => {
    dispatch({ type: EXPORT_GRID });
    let filters = getState()[NAME].filters;
    let store = getState()[NAME];

    exportGrid({ ...filters, provincia: filters.provincias && filters.provincias.value, cantidad: store.rowsPerPage })
        .then((result) => {
            dispatch({ type: EXPORT_GRID_END, payload: result.data });
            //const url = window.URL.createObjectURL();
            const blob = new Blob([result.data], { type: "application/vnd.ms-excel" });
            const filename = "afiliaciones.xlsx";

            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
        })
        .catch((e) => dispatch({ type: EXPORT_GRID_END, payload: e }))

};

export const changeFilterAction = (newValues) => dispatch => dispatch({ type: CHANGE_FILTER, payload: newValues });
//export const changePageAction = (page) => dispatch => dispatch({type:CHANGE_PAGE,payload: page});
export const changeRowsPerPageAction = (rows) => dispatch => dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rows });
