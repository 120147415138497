import React from "react";
import CustomTable from "./table/table";
import {
    changeFilterAction,
    changeRowsPerPageAction, exportGridAction,
    getAportesAction,
} from "../actions";
import { connect } from "react-redux";
import Loader from "../../../common/loader";
import {
    getAffiliations, getAsyncFilters, getCurrentPage,
    getFilters, getRowsPerPage,
    getTotalPages,
    isLoading
} from "../selectors";
import { createStructuredSelector } from "reselect";
import Filter from "./table/options";
import { routes } from "../../../router/routes";
import { ROWS_PER_PAGE_DEFAULT_OPTION } from "../../../config";

class MainPage extends React.Component {
    componentDidMount() {
        this.props.dispatch(getAportesAction(0));
    }

    render() {
        const { loading, filters, affiliations, totalPages,
            //userRole,
            currentPage, rowsPerPage } = this.props;
        return <div id={"main-page"}>
            <Loader loading={loading} />
            <Filter
                handleFilterChange={this.handleFilterChange}
                filters={filters}
                handleDownloadExcelClick={this.handleDownloadExcelClick}
            />
            <CustomTable
                rows={affiliations}
                handleDetailClick={this.handleDetailClick}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </div>
    };
    handleFilterChange = (newValues) => {
        this.props.dispatch(changeFilterAction(newValues));
        this.props.dispatch(getAportesAction(0));
    };
    handleDetailClick = (guid) => this.props.history.push(routes.details.path + "/" + guid);
    handleChangePage = (e, newPage) => {
        if (!e)
            return;
        let page = 1;
        try {
            page = newPage;
            //page = parseInt(newPage,10);
        } catch (e) {
            //Si por alguna razon material me devuelve algo que no puede parsearse a int vuelvo a la primera página
            page = 1;
        }
        this.props.dispatch(getAportesAction(page));
    };
    handleChangeRowsPerPage = (e) => {
        try {
            this.props.dispatch(changeRowsPerPageAction(parseInt(e.target.value)));
        } catch (e) {
            //Si por alguna razon material me devuelve algo que no puede parsearse a int muestro los default
            this.props.dispatch(changeRowsPerPageAction(ROWS_PER_PAGE_DEFAULT_OPTION));
        }
    };

    handleDownloadExcelClick = () => this.props.dispatch(exportGridAction);
}

export default connect(createStructuredSelector({
    loading: isLoading,
    filters: getFilters,
    affiliations: getAffiliations,
    totalPages: getTotalPages,
    rowsPerPage: getRowsPerPage,
    currentPage: getCurrentPage,
}))(MainPage)
