import React from "react";
import {Dialog,DialogTitle} from "@material-ui/core";
import {strings} from "../../../../../../localization/strings";
import FilterDialogContent from "./filterDialogContent";

const FilterModal = ({open,handleClose,filters,handleFilterChange,asyncFilters,canFilterByAsesor}) =>
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
    >
        <DialogTitle id="alert-dialog-title">{strings.filterModalTitle}</DialogTitle>
        <FilterDialogContent
            handleClose={handleClose}
            filters={filters}
            handleFilterChange={handleFilterChange}
            asyncFilters={asyncFilters}
            canFilterByAsesor={canFilterByAsesor}
        />
    </Dialog>;

export default FilterModal
