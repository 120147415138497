export const regulationStatus = {
    pending: 1,
    done: 2,
    expired: 3
};

export const sex = {
    male: 0,
    female: 1
};

export const userType = {
    children: 1
};