import React,{useState} from "react";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography/Typography";
import CustomTextField from "./text";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import styled from "styled-components";

const CustomTextArray = ({value,label,onChange}) =>{
    const [addingText,setAddingText] = useState(false);
    const [textToAdd,setTextToAdd] = useState("");
    return <TextArrayContainer className={"custom-input"}>
        <Typography variant={"h6"}>{label}</Typography>

        <OptionsContainer>
            {
                (value||[]).map( (v,k) =>
                    <Option key={k}>
                        <Typography variant={"subtitle1"}>{v}</Typography>
                        <IconButton onClick={()=>{onChange(value.filter( (i,j)=> j!==k ))}}><DeleteIcon/></IconButton>
                    </Option>)
            }
        </OptionsContainer>
        <AddContainer>
        {
            addingText ?
                <React.Fragment>
                    <CustomTextField
                        label={""}
                        value={textToAdd}
                        onChange={setTextToAdd}
                    />
                    <IconButton onClick={()=>{onChange([...(value||[]),textToAdd]);setAddingText(false);setTextToAdd("")}}>
                        <CheckIcon/>
                    </IconButton>
                </React.Fragment>:
                    <Tooltip position={"top"} title={"Agregar item"}>
                        <IconButton onClick={()=>setAddingText(true)}>
                            <AddIcon/>
                        </IconButton>
                    </Tooltip>
        }
        </AddContainer>
    </TextArrayContainer>
};

const AddContainer = styled.div`
    text-align:center
`;

const Option = styled.div`
    padding: 0 10px;
    display: flex;
    align-items: center;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
`;

const TextArrayContainer = styled.div`
    
`;

export default CustomTextArray
