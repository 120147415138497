import {regulationStatus, sex} from "./constants";

export const regulationStatusConstantStrings = {
    [regulationStatus.pending]: "Pendiente",
    [regulationStatus.done]: "Finalizado",
    [regulationStatus.expired]: "Expirado"
};

export const sexConstantsStrings = {
    [sex.male]: "Masculino",
    [sex.female]: "Femenino"
};