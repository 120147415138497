import {
    ADD_OBSERVATION,
    ADD_OBSERVATION_FULFILLED,
    ADD_OBSERVATION_REJECTED,
    CHANGE_BENEFICIARIO, CHANGE_PLAN_STATE, CHANGE_PLAN_STATE_FULFILLED,
    CHANGE_PLAN_STATE_REJECTED,
    GET_EVENTS,
    GET_EVENTS_FULFILLED,
    GET_EVENTS_REJECTED,
    GET_OBSERVATIONS,
    GET_OBSERVATIONS_FULFILLED,
    GET_OBSERVATIONS_REJECTED,
    GET_PROFILE,
    GET_PROFILE_FULFILLED,
    GET_PROFILE_REJECTED,
    GET_PROFILE_STATES,
    GET_PROFILE_STATES_FULFILLED,
    GET_PROFILE_STATES_REJECTED
} from "./actionTypes";

const initialState = {
    loadingProfile: false,
    profile: null,

    beneficiarioIndex: 0,

    loadingObservations: false,
    observations: [],

    loadingEvents: false,
    events: [],

    loadingProfileStates: false,
    profileStates: [],

    changingPlanState: false
};

export default function reducer(state=initialState, action){

    switch (action.type){
        case GET_PROFILE: return {...state,loadingProfile: true,profile:null};
        case GET_PROFILE_FULFILLED: return {...state,loadingProfile:false,profile: action.payload};
        case GET_PROFILE_REJECTED: return {...state,loadingProfile:false};

        case GET_OBSERVATIONS: return {...state,loadingObservations:true,observations:[]};
        case GET_OBSERVATIONS_FULFILLED: return {...state,loadingObservations:false,observations: action.payload};
        case GET_OBSERVATIONS_REJECTED: return {...state,loadingObservations:false};

        case GET_EVENTS: return {...state,loadingEvents:true,events:[]};
        case GET_EVENTS_FULFILLED: return {...state,loadingEvents:false,events: action.payload};
        case GET_EVENTS_REJECTED: return {...state,loadingEvents:false};

        case ADD_OBSERVATION: return {...state,loadingObservations:true};
        case ADD_OBSERVATION_FULFILLED: return {...state,loadingObservations:false};
        case ADD_OBSERVATION_REJECTED: return {...state,loadingObservations:false};

        case GET_PROFILE_STATES: return {...state, loadingProfileStates:true,profileStates:[]};
        case GET_PROFILE_STATES_FULFILLED: return {...state, loadingProfileStates:false,profileStates: action.payload};
        case GET_PROFILE_STATES_REJECTED: return {...state, loadingProfileStates:false};

        case CHANGE_PLAN_STATE: return {...state,changingPlanState:true};
        case CHANGE_PLAN_STATE_FULFILLED: return {...state,changingPlanState:false};
        case CHANGE_PLAN_STATE_REJECTED: return {...state,changingPlanState:false};

        case CHANGE_BENEFICIARIO: return {...state, beneficiarioIndex: action.payload};

        default: return state
    }
}