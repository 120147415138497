import {filters, modalFilters} from "../../../../model";
import Input from "../../../../../../common/input/index";
import {inputTypes} from "../../../../../../utils/types";
import React from "react";
import {Button, DialogActions, DialogContent} from "@material-ui/core";
import {strings} from "../../../../../../localization/strings";


class FilterDialogContent extends React.Component{
    state={...this.props.filters};

    render(){
        const {handleClose,asyncFilters,canFilterByAsesor} = this.props;

        const filteredModalFilters = modalFilters.filter(filter => filter.name !== filters.asesor.name || canFilterByAsesor);

        const filterValues = this.state;
        return [
            <DialogContent key={"content"}>
                <form className={"custom-form"}>
                    {filteredModalFilters.map((filter,key)=>
                        <div key={key} className={filter.type === inputTypes.autosuggest ? "form-field-autosuggest-container":"form-field-container"}>
                            <Input
                                onChange={(value)=>this.handleFilterChange(filter.name,value)}
                                value={filterValues[filter.name]||""}
                                type={filter.type}
                                label={filter.label}
                                loading={filter.async? asyncFilters[filter.name].loading:false}
                                options={(filter.type === inputTypes.select || filter.type === inputTypes.autosuggest) &&
                                        (filter.options || asyncFilters[filter.name].options)}
                            />
                        </div>
                    )}
                </form>
            </DialogContent>,
            <DialogActions key={"actions"}>
                <Button onClick={handleClose} color="primary">
                    {strings.cancel}
                </Button>
                <Button onClick={this.handleConfirmDialog} color="primary" autoFocus>
                    {strings.agree}
                </Button>
            </DialogActions>
        ]
    }

    handleFilterChange = (name,value) => this.setState({[name]:value});
    handleConfirmDialog = () => {
        this.props.handleFilterChange(this.state);
        this.props.handleClose();
    }
}

export default FilterDialogContent
