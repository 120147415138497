import PhoneIcon from "@material-ui/icons/Phone";
import CallEndIcon from "@material-ui/icons/CallEnd";
import DateIcon from "@material-ui/icons/DateRange";
import ChildIcon from "@material-ui/icons/ChildFriendly";
import AvatarIcon from "@material-ui/icons/AccountCircle";
import CashIcon from "@material-ui/icons/AttachMoney";
import AccessIcon from "@material-ui/icons/Accessibility";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import React, { useState } from "react";
import { strings } from "../../localization/strings";
import { formatDate } from "../../utils/date";
import { inputTypes } from "../../utils/types";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import Button from "@material-ui/core/Button";
import { DOWNLOAD_DNI_ENDPOINT, DOWNLOAD_RECEIPT } from "../../shared/connections";
import { permissions, userHasPermission } from "../../shared/permissions";
import { regulationStatusConstantStrings, sexConstantsStrings } from "../../shared/constantStrings";
import { regulationStatus, userType } from "../../shared/constants";
import Input from "../../common/input";
import { changeAffiliationStatus } from "../../shared/api";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Typography from "@material-ui/core/Typography/Typography";

export const eventTypes = {
    observation: 0,
    call: 2,
};

export const eventIcons = {
    [eventTypes.observation]: <EyeIcon color={"primary"} />,
    [eventTypes.call]: <PhoneIcon color={"primary"} />
};

export const eventNames = {
    [eventTypes.observation]: strings.observations,
    [eventTypes.call]: strings.call
};

export const personalInfoFields = {
    name: { prop: "nombre", label: strings.name },
    surname: { prop: "apellido", label: strings.surname },
    mail: { prop: "mail", label: strings.mail },
    phone: { prop: "telefonomovil", label: strings.phone, icon: <PhoneIcon color={"primary"} />, type: inputTypes.tel },
    gender: { prop: "sexo", label: strings.gender, format: (v) => sexConstantsStrings[v], icon: <AccessIcon color={"primary"} /> },
    rejectReason: { title: strings.rejectReason, fieldProp: "motivo", format: (value) => value && <Tooltip title={value.detalle} placement="top" aria-label="add"><Typography variant={"subtitle1"}>{value.motivo}</Typography></Tooltip> },
    years: { prop: "edad", label: strings.years },
    dni: { prop: "dni", label: strings.dni, format: (value, userPermissions) => userHasPermission(userPermissions, permissions.dni) && value },
    children: { prop: "beneficiarios", label: strings.children, format: (beneficiarios) => (beneficiarios || []).filter(beneficiario => beneficiario.type === userType.children).length, icon: <ChildIcon color={"primary"} /> },
    plan: { prop: "plan", label: strings.plan, format: (plan) => plan && plan.nombre },
    beneficiarios: { prop: "beneficiarios", label: strings.beneficiarios },
};

export const ddjFields = {
    weight: { prop: "peso", label: strings.weight },
    height: { prop: "altura", label: strings.height },
    imc: { prop: "imc", label: strings.imc },
};

const AffiliationTypeComponent = ({ value, permissions, fields }) => {
    const [newValue, setNewValue] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = v => {
        setLoading(true);
        changeAffiliationStatus({
            idafiliacion: fields["ID"],
            idestadodesresregulacion: v
        })
            .then(() => setNewValue(v))
            .finally(() => {
                setLoading(false);
            })
    };

    return value === 1 &&
        <Input
            loading={loading}
            onChange={handleChange}
            value={newValue || fields["estadodesregulacion"]} options={[
                { value: regulationStatus.expired, label: regulationStatusConstantStrings[regulationStatus.expired] },
                { value: regulationStatus.done, label: regulationStatusConstantStrings[regulationStatus.done] },
                { value: regulationStatus.pending, label: regulationStatusConstantStrings[regulationStatus.pending] }
            ]} label={strings.regulationStatus} type={inputTypes.select} />
};

export const affiliationFields = {
    validTo: { prop: "FechaVigencia", label: strings.validTo, format: formatDate, icon: <DateIcon color={"primary"} /> },
    affiliation: { prop: "TimeStamp", label: strings.affiliation, format: formatDate, icon: <DateIcon color={"primary"} /> },
    input: { prop: "Aporte", label: strings.input, icon: <CashIcon color={"primary"} /> },
    calls: { prop: "CantLlamadas", label: strings.calls, icon: <CallEndIcon color={"primary"} /> },
    affiliationType: {
        prop: "TipoAfiliacion", label: strings.regulationStatus, icon: <AvatarIcon color={"primary"} />,
        format: (value, userPermissions, fields) =>
            userHasPermission(userPermissions, permissions.changeRegulationStatus) &&
            <AffiliationTypeComponent value={value} permissions={permissions} fields={fields} />
    },
    planPrice: {
        prop: "precioProporcional", label: strings.planPrice,
        format: (value, userPermissions, fields) =>
            userHasPermission(userPermissions, permissions.readPlanPrices) && value
    },
    planMonthlyPrice: {
        prop: "precioMensual", label: strings.planMonthlyPrice,
        format: (value, userPermissions, fields) =>
            userHasPermission(userPermissions, permissions.readPlanPrices) && value
    },
    planNextMonthPrice: {
        prop: "precioMensual", label: strings.planNextMonthPrice,
        format: (value, userPermissions, fields) =>
            userHasPermission(userPermissions, permissions.readPlanPrices) && value
    },
    marcaEspecial: { prop: "MarcaEspecial", label: strings.marcaEspecial, format: (value, userPermissions) => (userHasPermission(userPermissions, permissions.marcaEspecial) && value) || "" },
    id: {
        prop: "ID", label: "", icon: " ",
        format: (value, userPermissions, fields) =>
            [fields.Aportes && fields.Aportes[0] && fields.Aportes[0].id && <Button className={"download-button"} key={"recibo"} color={"primary"} variant="contained" component="span">
                <a href={DOWNLOAD_RECEIPT + value + "/" + (fields.Aportes && fields.Aportes[0] && fields.Aportes[0].id)} target={"_blank"}><DownloadIcon />Recibo</a>
            </Button>, userHasPermission(userPermissions, permissions.dni) &&
            <Button className={"download-button"} key={"dni"} color={"secondsary"} variant="contained" component="span">
                <a href={DOWNLOAD_DNI_ENDPOINT + value} target={"_blank"}><DownloadIcon />DNI</a>
            </Button>]
    },
    promotion: { prop: "Promocion", label: strings.promotion, icon: <DateIcon color={"primary"} /> },

};

export const infoList = [
    personalInfoFields.phone,
    personalInfoFields.gender,
    personalInfoFields.years,
    personalInfoFields.dni,
    personalInfoFields.rejectReason,
    personalInfoFields.children,
    personalInfoFields.plan,
    ddjFields.weight,
    ddjFields.height,
    ddjFields.imc,
    affiliationFields.validTo,
    affiliationFields.affiliation,
    affiliationFields.calls,
    affiliationFields.marcaEspecial,
    affiliationFields.affiliationType,
    affiliationFields.input,
    affiliationFields.planPrice,
    affiliationFields.planMonthlyPrice,
    affiliationFields.planNextMonthPrice,
    affiliationFields.promotion,
    affiliationFields.id,
];
