import { strings } from "../../localization/strings";
import { inputTypes } from "../../utils/types";
import moment from "moment";
import { constantStrings, regulationStatusConstantStrings } from "../../shared/constantStrings";
import { regulationStatus } from "../../shared/constants";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Typography from "@material-ui/core/Typography/Typography";

export const orderProps = {
    asesores: "codasesor",
    //fechafiliacion: "fechafiliacion",
    numeroSocio: "numerosocio",
    fechavigencia: "fechavigencia",
    nombre: "nombreapellido",
    //mail:"mail",
    provincia: "provincia"
};

export const columns = [
    { title: strings.dni, fieldProp: "dni", index: 0 },
    { title: strings.name, fieldProp: "nombre", index: 1, order: true },
    { title: strings.state, fieldProp: "estado", index: 2 },
    { title: strings.rejectReason, fieldProp: "motivo", index: 3, format: (value) => <Tooltip title={value.detalle} placement="top" aria-label="add"><Typography variant={"subtitle2"}>{value.motivo}</Typography></Tooltip> },
    { title: strings.provincia, fieldProp: "provincia", index: 4, order: true },
    { title: strings.startDate, fieldProp: "fechavigencia", index: 5, order: true, format: (value) => (moment(value).isValid() && moment(value).format("DD/MM/YYYY")) || value },
    //{ title: strings.calls, fieldProp: "cantLlamadas", index: 6 },
    //{ title: strings.regulationStatus, fieldProp: "estadodesregulacion", index: 7, format: value => regulationStatusConstantStrings[value] },
    //{ title: strings.afiliationDate, fieldProp: "fechaafiliacion", index: 8, format: (value) => (moment(value).isValid() && moment(value).format("DD/MM/YYYY")) || value },
    { title: strings.asessorCode, fieldProp: "asesores", index: 9, format: (value) => (value && value.join && value.join(", ")) || "" },
    { title: strings.memberNumer, fieldProp: "numeroSocio", index: 10 },
    { title: strings.detail, fieldProp: "guid", index: 11, inputType: inputTypes.linkButton }
];

export const filters = {
    quantity: { name: "cantidad", inputType: inputTypes.number, label: strings.quantity, type: "number" },
    dni: { name: "dni", inputType: inputTypes.text, label: strings.dni, type: "number" },
    state: { name: "estado", inputType: inputTypes.select, async: true, label: strings.state },
    provincias: { name: "provincias", inputType: inputTypes.autosuggest, async: true, label: strings.provincias },
    startDate: { name: "fechadesde", inputType: inputTypes.date, type: "date", label: strings.startDate },
    endDate: { name: "fechaHasta", inputType: inputTypes.date, type: "date", label: strings.endDate },
    invalidoComercial: { name: "invalidoComercial", inputType: inputTypes.boolean, label: strings.invalidoComercial },
    invalidoMedico: { name: "invalidoMedico", inputType: inputTypes.boolean, label: strings.invalidoMedico },
    mail: { name: "mail", inputType: inputTypes.mail, label: strings.mail },
    page: { name: "pagina", inputType: inputTypes.text, label: strings.page },
    userRole: { name: "rolusuario", inputType: inputTypes.number, label: strings.userRole },
    asesor: { name: "asesor", inputType: inputTypes.autosuggest, async: true, label: strings.asesor },
    smart: { name: "smart", inputType: inputTypes.boolean, label: strings.smartPlan },
    regulationStatus: {
        name: "estadodesregulacion", inputType: inputTypes.select, options: [
            { value: -1, label: strings.all },
            { value: regulationStatus.expired, label: regulationStatusConstantStrings[regulationStatus.expired] },
            { value: regulationStatus.done, label: regulationStatusConstantStrings[regulationStatus.done] },
            { value: regulationStatus.pending, label: regulationStatusConstantStrings[regulationStatus.pending] },
        ], label: strings.regulationStatus
    }
};

export const modalFilters = [
    filters.dni,
    //filters.mail,
    filters.startDate,
    filters.endDate,
    filters.state,
    //filters.regulationStatus,
    filters.smart,
    filters.asesor,
    filters.provincias
];
