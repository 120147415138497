import React from "react";
import {IconButton,Toolbar,Typography,Menu,MenuItem} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import FilterModal from "./filterDialog/index";
import {strings} from "../../../../../localization/strings";

class Options extends React.Component{
    state={modalIsOpen:false,menuAnchor:null};

    render(){
        const {filters,asyncFilters,handleFilterChange,handleDownloadExcelClick,canFilterByAsesor} = this.props;
        const {modalIsOpen,menuAnchor} = this.state;
        return <div>
            <Toolbar className={"filter-toolbar"}>
                <Typography variant="h6" id={"table-title"}>
                    {strings.subscriptionsTableTitle}
                </Typography>
                <div className={"spacer"} />
                <div>
                    <IconButton onClick={this.handleOpenMenu}>
                        <MoreIcon/>
                    </IconButton>
                </div>
            </Toolbar>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={this.handleCloseMenu}
            >
                <MenuItem onClick={this.handleOpenModal}>{strings.filter}</MenuItem>
                <MenuItem onClick={()=> {this.handleCloseMenu();handleDownloadExcelClick();}}>{strings.exportToExcel}</MenuItem>
            </Menu>
            <FilterModal
                canFilterByAsesor={canFilterByAsesor}
                handleClose={this.handleCloseModal}
                open={modalIsOpen}
                filters={filters}
                asyncFilters={asyncFilters}
                handleFilterChange={handleFilterChange}
            />
        </div>
    }

    handleCloseMenu = () => this.setState({menuAnchor:null});
    handleOpenMenu = (anchor) => this.setState({menuAnchor:anchor.currentTarget});

    handleCloseModal = () => this.setState({modalIsOpen:false});
    handleOpenModal = () => {this.setState({modalIsOpen:true});this.handleCloseMenu()};
}

export default Options