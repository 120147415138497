export const permissions = {
    djs: 1,
    observations: 2,
    events: 3,
    recibo: 4,
    dni: 5,
    aportes:6,
    addCall:7,
    addObservation: 8,
    marcaEspecial: 9,
    asesoresFilter: 10,
    changeRegulationStatus: 11,
    changeAffiliationStatus: 12,
    readPlanPrices: 13
};

export const userHasPermission = (userPermissions,permission) => userPermissions && userPermissions.map(value => value.toString()).includes(permission.toString());

// 1 - Ver DJS
// 2 - Ver Llamados
// 3 - Ver Eventos
// 4 - Ver Recibo
// 5 - Ver DNI
// 6 - Ver Aportes
// 7 - Agregar llamado
// 8 - Agregar observacion
// 9 - Ver marcas especiales
// 10- Ver filtro asesores
