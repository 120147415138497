import React,{useState} from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import {strings} from "../../localization/strings";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ClearIcon from "@material-ui/icons/Delete";
import Input from "../input/index";
import Typography from "@material-ui/core/Typography/Typography";
import {colors} from "../../config/colors";
import FormButton from "../formButton";
import FormTitle from "../formTitle";
import {inputTypes} from "../../utils/types";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";

const cols = {
    "1":12,
    "2":6,
    "3":4,
    "4":3,
    "5":3,
    "6":2,
    "7":2,
    "8":2,
    "9":2,
    "10":2,
    "11":2,
    "12":1
};

const DEFAULT_COLUMNS = 1;

const sizes = {
    "sm": "250px",
    "md": "500px"
};

const SubmitButton = ({onSubmit,label,forceLoading}) =>{
    const [loading,setLoading] = useState(false);

    const onClick = (e) =>{
        setLoading(true);
        onSubmit(e)
            .finally(()=> setLoading(false))
    };
    return <SubmitButtonContainer>
        <FormButton
            onClick={onClick}
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading || forceLoading}
            label={
                loading || forceLoading?
                    <CustomCircularProgress/>:
                    label
            }
        />
    </SubmitButtonContainer>
};

const CustomCircularProgress = styled(CircularProgress)`
    width:27px!important;
    height:27px!important;
`;

class CustomControlledForm extends React.Component{
    state={
        loading: this.props.loading||false,
        successDialogOpen: false,
        values:{...(this.props.defaultValues||{})},response:null};

    onClearButtonClick = () => this.setState({values:{}});
    handleCloseSuccessDialog = () => this.setState({successDialogOpen:false});

    onSubmitForm = (forceLoad) => {
        const {onError,inputs,onSubmit,showSuccessDialog} = this.props;
        const {values}= this.state;
        return new Promise(async (resolve, reject) => {
            if(forceLoad)
                this.setState({loading:true});

            for (let i = 0; i < inputs.length; i++) {
                if ( (!inputs[i].dependsOn || !inputs[i].toBe || inputs[i].toBe(values[inputs[i].dependsOn])) && inputs[i].validator){
                    const validation = inputs[i].validator(values[inputs[i].name], values);
                    if(validation !== true){
                        onError && onError(inputs[i],validation);
                        this.setState({loading:false});
                        return reject(inputs[i])
                    }
                }
            }
            try{
                const response = await onSubmit(values);
                if(showSuccessDialog)
                    this.setState({successDialogOpen:true,response:response.data});
                resolve();
            }catch(e){
                onError && onError(e);
                reject(e);
            }finally{
                this.setState({loading:false})
            }
        });
    };

    render(){
        const {showClearButton,
            disabled,
            align,
            showSuccessDialog,successDialogTitle,successDialogMessage,successDialogFirstActionMsg,successDialogFirstAction,successDialogSecondaryActionMsg,successDialogSecondaryAction,
            fullWidthInputs,
            inputs,columns,title,submitButtonLabel,asyncInputs,size,formHelpText,postInputs,secondaryButtonLabel,onSecondaryButtonClick} = this.props;
        const {values,successDialogOpen,response,loading}= this.state;
        return <FormPaperContainer size={size && sizes[size]} align={align}>
            <FormContainer>
                <InputsContainer>
                    {title && <FormTitle size={20} align={"center"}>{title}</FormTitle>}
                    <InnerInputsContainerContainer container>
                        {
                            (inputs && inputs.map && inputs).map( (i,key) =>
                                (!i.dependsOn || !i.toBe || i.toBe(values[i.dependsOn])) &&
                                <Grid item key={i.name} xs={cols[columns||DEFAULT_COLUMNS]}>
                                    <InputContainer align={align} fullWidth={i.fullWidth}>
                                        {
                                            i.async && asyncInputs && (!asyncInputs[i.name] || asyncInputs[i.name].length <= 0) ?
                                                <EmptyLoadingInput label={i.label}/>:
                                                <Input key={i.name}
                                                       InputLabelProps={{shrink:true}}
                                                       {...i}
                                                       fullWidth={fullWidthInputs!==undefined?fullWidthInputs:true}
                                                       options={(i.async&&asyncInputs?asyncInputs[i.name]:i.options)||[]}
                                                       value={values[i.name]||""}
                                                       disabled={disabled}
                                                       onChange={(v)=> this.setState({values: {...this.state.values,[i.name]: i.format? i.format(v):v}}) }
                                                       onKeyPress= {(event) => {
                                                           if( (inputs.length-1 === key) && event.key === 'Enter')
                                                            this.onSubmitForm(true)
                                                        }}
                                                />
                                        }
                                    </InputContainer>
                                </Grid>
                            )
                        }
                    </InnerInputsContainerContainer>
                    {postInputs}
                    <ButtonsContainer>
                        <SubmitButton
                            forceLoading={loading}
                            onSubmit={this.onSubmitForm}
                            label={submitButtonLabel || strings.submit}
                        />
                        {
                            secondaryButtonLabel &&
                            <SecondaryButton onClick={onSecondaryButtonClick}>{secondaryButtonLabel}</SecondaryButton>
                        }
                    </ButtonsContainer>
                </InputsContainer>
                {
                    formHelpText || null
                }

                {
                    showClearButton &&
                    <Tooltip title={strings.clear} placement={"top"}>
                        <CustomCleanIcon onClick={this.onClearButtonClick}>
                            <ClearIcon/>
                        </CustomCleanIcon>
                    </Tooltip>
                }
            </FormContainer>
            {
                showSuccessDialog &&
                <Dialog
                    open={successDialogOpen}
                    onClose={()=>this.setState({successDialogOpen:false})}
                >
                    <DialogTitle id="alert-dialog-title">{successDialogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {successDialogMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            successDialogFirstActionMsg &&
                            <Button onClick={() => successDialogFirstAction?successDialogFirstAction(response):this.handleCloseSuccessDialog()} color="primary">
                                {successDialogFirstActionMsg}
                            </Button>
                        }
                        {
                            successDialogSecondaryActionMsg &&
                            <Button onClick={() => successDialogSecondaryAction? successDialogSecondaryAction(response):this.handleCloseSuccessDialog()} color="primary" autoFocus>
                                {successDialogSecondaryActionMsg}
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </FormPaperContainer>
    }
}

const SecondaryButton = styled(Typography)`
    &.MuiTypography-root{
        margin-top: 31px;
        text-decoration: underline;
        color: #2FA49F;
        cursor: pointer;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`;

const FormPaperContainer = styled.div`
    padding: 28px ${props => props.align ? '0':'25px'} 16px ${props => props.align ? '0':'25px'};
    width: ${props => props.size||"default"};
    max-width:100%;
    position: relative;
`;
const CustomCleanIcon = styled(IconButton)`
    &.MuiButtonBase-root{
        position: absolute;
        top: 0;
        right: 0;
    }
`;

const EmptyLoadingInput = ({label}) =>
    <Input
        inputType={inputTypes.text}
        label={label}
        fullWidth={true}
        disabled={true}
        InputLabelProps={{
            shrink: true,
        }}
        InputProps={{
            endAdornment: <InputAdornment position="end"><TinyCircularProgress/></InputAdornment>,
        }}
    />;

const TinyCircularProgress = styled(CircularProgress)`
    height: 25px!important;
    width: 25px!important;
`;

const FormContainer = styled.div`
    max-width: 100%;
    width: 500px;
    margin: auto;
`;

const InputContainer = styled.div`
    max-width: 100%;
    width: ${props => props.fullWidth ? "100%":"350px"};
    margin: 11px ${props => props.align? '0':'auto'};
    .custom-input{
        width:100%;
    }
`;

const SubmitButtonContainer = styled.div`
    padding-top: 10px;
    max-width: 250px;
    margin: auto;
    button{
       height: 50px;
    }
`;
const InputsContainer = styled.div`
`;

const InnerInputsContainerContainer = styled(Grid)`
    margin: 15px 0 0 0;
`;

export default CustomControlledForm
