import { combineReducers } from 'redux';
import appReducer, { appReducerName } from "../features/app/index";
import loginReducer, { loginReducerName } from "../features/login/index";
import toastReducer, { toastReducerName } from "../features/snackbar/index";
import mainReducer, { mainReducerName } from "../features/main/index";
import aportesReducer, { aportesReducerName } from "../features/reports/index";

import detailsReducer, { detailsReducerName } from "../features/details/index";
import asesorBlacklistReducer, { asesorBlacklistReducerName } from "../features/asesorBlacklist";

const rootReducer = combineReducers({
    [loginReducerName]: loginReducer,
    [mainReducerName]: mainReducer,
    [appReducerName]: appReducer,
    [toastReducerName]: toastReducer,
    [detailsReducerName]: detailsReducer,
    [asesorBlacklistReducerName]: asesorBlacklistReducer,
    [aportesReducerName]: aportesReducer
});

export default rootReducer;
