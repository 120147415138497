import {deleteKey, getValueFromKey, isKeyPresent, setKeyValue} from "../../utils/localStorage";

const authInfoKey = 'LoginData';

export const isLoginDataPresent = () => isKeyPresent(authInfoKey);

export const setLoginData = (value) => setKeyValue(authInfoKey,value);

export const getLoginData = () => getValueFromKey(authInfoKey);

export const removeLoginData = () => deleteKey(authInfoKey);


