import axios from "axios";
import {
    AFILIACIONES_ENDPOINT,
    LOGIN_ENDPOINT,
    STATES_ENDPOINT,
    PROVINCIAS_ENDPOINT,
    ASESORES_ENDPOINT,
    PROFILE_ENDPOINT,
    OBSERVATIONS_ENDPOINT,
    ASESOR_DELETE_ENDPOINT,
    ASESOR_PUT_ENDPOINT,
    EVENTS_ENDPOINT,
    PROFILE_STATES_ENDPOINT,
    CHANGE_PLAN_ENDPOINT, REGULATION_STATUS_ENDPOINT, EXPORT_GRID_ENDPOINT, ASESOR_ENDPOINT,
    REPORTS_ENDPOINT,
    EXPORT_REPORTS_ENDPOINT,
    EXPORT_APORTES_GRID_ENDPOINT,
    APORTES_ENDPOINT
} from "./connections";
import { getAuthInfo, isAuthInfoPresent, removeAuthInfo, setAuthInfo } from "../features/login/storeSession";
import { EXPORT_GRID } from "../features/main/actionTypes";


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent

    if (config.url.includes("/authenticate"))
        return config;

    // eslint-disable-next-line
    return { ...config, headers: { ...config.headers, ["Authorization"]: "Bearer " + (isAuthInfoPresent() ? getAuthInfo().token : "") } };
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
//
axios.interceptors.response.use(function (response) {
    // Do something with response data

    return response;
}, function (error) {

    // eslint-disable-next-line
    if (error.response && (error.response.status == 403 || error.response.status == 401))
        removeAuthInfo();

    // Do something with response error
    return Promise.reject(error);
});

export const objectToQueryString = (obj) =>
    Object.keys(obj)
        .filter(key => obj[key] !== null && obj[key] !== undefined && obj[key] !== "")
        .map(key => key + '=' + obj[key]).join('&');

export const exportGrid = (filters) => axios.post(EXPORT_GRID_ENDPOINT, filters, { responseType: 'blob' });

export const exportAportes = filters => axios.get(EXPORT_APORTES_GRID_ENDPOINT + "?" + objectToQueryString(filters), { responseType: 'blob' });

export const getAportes = filters => axios.get(APORTES_ENDPOINT + "?" + objectToQueryString(filters));


export const logIn = (data) => axios.post(LOGIN_ENDPOINT, data);

export const getAffiliations = (data) => axios.post(AFILIACIONES_ENDPOINT, data);

export const getStates = () => axios.get(STATES_ENDPOINT);

export const getProvincias = () => axios.get(PROVINCIAS_ENDPOINT);

export const getAsesores = () => axios.get(ASESORES_ENDPOINT);

export const getReports = (data) => axios.get(REPORTS_ENDPOINT + `?pagina=${data.pagina - 1}&cantidad=15`);

export const exportReport = (filters) => axios.get(EXPORT_REPORTS_ENDPOINT + `?pagina=${filters.pagina - 1}&cantidad=15`, filters, { responseType: 'blob' });

export const getProfile = (guid) => axios.get(PROFILE_ENDPOINT + `/${guid}`);

export const getObservations = (data) => axios.get(EVENTS_ENDPOINT + `/${data.guid}/0,2/${data.acting || "M"}`);

export const addObservation = (data) => axios.post(OBSERVATIONS_ENDPOINT, data);

export const getEvents = (data) => axios.get(EVENTS_ENDPOINT + `/${data.guid}/1,4,3/${data.acting || "M"}`);

export const getProfileStates = (data) => axios.get(PROFILE_STATES_ENDPOINT + `/${data.currentState}/47`);

export const changePlan = (data) => axios.post(CHANGE_PLAN_ENDPOINT, data);

export const changeAffiliationStatus = (data) => axios.post(REGULATION_STATUS_ENDPOINT, data);

export const createAsesor = (data) => axios.post(ASESOR_ENDPOINT, data);
export const deleteAsesor = (data) => axios.delete(ASESOR_DELETE_ENDPOINT + "?idListaAsesores=" + data.id);
export const updateAsesor = (data) => axios.put(ASESOR_PUT_ENDPOINT, data);

export const getBlacklistAseores = ({ orderBy, orderDirection }) => axios.get(ASESOR_ENDPOINT + (orderBy ? (`?orderBy=${orderBy}&direccion=${orderDirection || "desc"}`) : ""));
