import NAME from "./constants";

export const GET_PROFILE = NAME + "/GET_PROFILE";
export const GET_PROFILE_FULFILLED = NAME + "/GET_PROFILE_FULFILLED";
export const GET_PROFILE_REJECTED = NAME + "/GET_PROFILE_REJECTED";

export const GET_OBSERVATIONS = NAME + "/GET_OBSERVATIONS";
export const GET_OBSERVATIONS_FULFILLED = NAME + "/GET_OBSERVATIONS_FULFILLED";
export const GET_OBSERVATIONS_REJECTED = NAME + "/GET_OBSERVATIONS_REJECTED";

export const GET_EVENTS = NAME + "/GET_EVENTS";
export const GET_EVENTS_FULFILLED = NAME + "/GET_EVENTS_FULFILLED";
export const GET_EVENTS_REJECTED = NAME + "/GET_EVENTS_REJECTED";

export const ADD_OBSERVATION = NAME + "/ADD_OBSERVATION";
export const ADD_OBSERVATION_FULFILLED = NAME + "/ADD_OBSERVATION_FULFILLED";
export const ADD_OBSERVATION_REJECTED = NAME + "/ADD_OBSERVATION_REJECTED";

export const GET_PROFILE_STATES = NAME + "/GET_PROFILE_STATES";
export const GET_PROFILE_STATES_FULFILLED = NAME + "/GET_PROFILE_STATES_FULFILLED";
export const GET_PROFILE_STATES_REJECTED = NAME + "/GET_PROFILE_STATES_REJECTED";

export const CHANGE_BENEFICIARIO = NAME + "/CHANGE_BENEFICIARIO";

export const CHANGE_PLAN_STATE = NAME + "/CHANGE_PLAN_STATE";
export const CHANGE_PLAN_STATE_FULFILLED = NAME + "/CHANGE_PLAN_STATE_FULFILLED";
export const CHANGE_PLAN_STATE_REJECTED = NAME + "/CHANGE_PLAN_STATE_REJECTED";

