import React from "react";
import {connect} from "react-redux";
import {
    getAffiliation, getBeneficiarioIndex, getBeneficiarios,
    getDjs,
    getEvents,
    getObservations,
    getPersonalInfo, getPlan, getProfileStates,
    isLoadingEvents, isLoadingObservations,
    isLoadingProfile, isLoadingProfileStates
} from "../selectors";
import {createStructuredSelector} from "reselect";
import {Grid} from "@material-ui/core";
import Events from "./events";
import Profile from "./profile/profile";
import {
    addObservationAction, changeBeneficiarioAction, changePlanStateAction,
    getEventsAction,
    getObservationsAction,
    getProfileAction,
    getProfileStatesAction
} from "../actions";
import {routes} from "../../../router/routes";
import {strings} from "../../../localization/strings";
import Questions from "./questions";
import {getPermissions, hasPermission} from "../../login/selectors";
import {permissions} from "../../../shared/permissions";

class DetailsPage extends React.Component{
    render(){
        const {
            personalInfo,
            affiliation,
            djs,
            loadingProfile,
            loadingObservations,
            loadingEvents,
            observations,
            events,
            profileStates,
            loadingProfileStates,
            beneficiarioIndex,
            beneficiarios,
            plan,
            canAddObservation,
            userPermissions,
            canChangeAffiliationStatus,
            canReadPlanPrices
        } = this.props;

        return <div id={"details-page"}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Profile
                        personalInfo={!loadingProfile && personalInfo}
                        affiliation={affiliation}
                        djs={djs}
                        changePlanState={this.changePlanState}
                        loading={loadingProfile}
                        plan={plan}
                        profileStates={profileStates}
                        loadingProfileStates={loadingProfileStates}
                        changeBeneficiario={this.changeBeneficiario}
                        beneficiarioIndex={beneficiarioIndex}
                        userPermissions={userPermissions}
                        canReadPlanPrices={canReadPlanPrices}
                        canChangeAffiliationStatus={canChangeAffiliationStatus}
                        beneficiarios={beneficiarios}
                    />
                    <Questions answers={djs && djs.respuestas}
                               title={strings.questions}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Events
                        observations={observations}
                        loadingObservations={loadingObservations}
                        events={events}
                        loadingEvents={loadingEvents}
                        addObservation={this.addObservation}
                        canAddObservation={canAddObservation}
                    />
                </Grid>
            </Grid>
        </div>
    };

    componentDidMount(){
        if(! this.props.match.params || ! this.props.match.params.guid)
            this.props.history.replace(routes.main.path);

        const guid = this.props.match.params.guid;

        this.props.dispatch(getProfileAction(guid))
            .then((response)=> {
                this.props.dispatch(getProfileStatesAction(response.afiliacion.EstadoBackOffice))
            });

        this.props.dispatch(getObservationsAction(guid));
        this.props.dispatch(getEventsAction(guid));
    }

    changeBeneficiario = (beneficiarioIndex) => this.props.dispatch(changeBeneficiarioAction(beneficiarioIndex));
    addObservation = (observationType, detail) => this.props.dispatch(addObservationAction(this.props.match.params.guid,observationType,detail));
    changePlanState = (state, observation, type) => {
        const guid = this.props.match.params.guid;
        this.props.dispatch(changePlanStateAction(state,observation,type))
            .then(()=>
                this.props.dispatch(getProfileAction(guid))
                    .then((response)=> {
                        this.props.dispatch(getProfileStatesAction(response.afiliacion.EstadoBackOffice))
                    })
            );
    };
}

export default connect(createStructuredSelector({
    personalInfo: getPersonalInfo,
    affiliation: getAffiliation,
    djs: getDjs,
    loadingProfile: isLoadingProfile,
    loadingObservations: isLoadingObservations ,
    loadingEvents: isLoadingEvents,
    observations: getObservations,
    events: getEvents,
    profileStates: getProfileStates,
    loadingProfileStates: isLoadingProfileStates,
    beneficiarioIndex: getBeneficiarioIndex,
    beneficiarios: getBeneficiarios,
    plan: getPlan,
    canAddObservation: hasPermission(permissions.addObservation),
    canChangeAffiliationStatus: hasPermission(permissions.changeAffiliationStatus),
    canReadPlanPrices: hasPermission(permissions.readPlanPrices),
    userPermissions: getPermissions
}))(DetailsPage)
