import React from "react";
import Input from "./index";
import {inputTypes} from "../../utils/types";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MoreIcon from "@material-ui/icons/Add";

const CustomNorms = ({value,onChange,loading,options,levels}) =>{

    function handleFieldChange(index,field,newValue){
        let newArray = [...value];
        newArray[index][field] = newValue;
        onChange(newArray);
    }
    function handleAddNorm(){
        onChange([...value,{}])
    }

    return <div>
        {
            value.map( (norm,i) =>
                <Paper key={i}>
                    <Input
                        options={options||[]}
                        type={inputTypes.select}
                        value={norm.norm}
                        onChange={(newValue)=>handleFieldChange(i,"norm",newValue)}
                    />

                    <Input
                        options={levels}
                        type={inputTypes.select}
                        value={norm.level}
                        onChange={(newValue)=>handleFieldChange(i,"level",newValue)}
                    />
                </Paper>
            )
        }
        <IconButton onClick={handleAddNorm}>
            <MoreIcon/>
        </IconButton>
    </div>;
};

export default CustomNorms