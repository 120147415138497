import React from "react";
import {withRouter} from "react-router-dom";
import {strings} from "../../localization/strings";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LogoutIcon from '@material-ui/icons/MeetingRoom';
import Logo from "../../assets/images/omint.svg";
import AvatarIcon from "@material-ui/icons/People";


const colors = {darkMainColor:"#1b2756",mainColor: "#1b2756"};
const drawerWidth = 236;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor:"#E8F3FF"
    },
    drawerHeader: {
        paddingTop: 22,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(0, 1),
        backgroundColor:"#F5FAFF"
    },
    content: {
        flexGrow: 1,
        //padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const NavBar = ({routes,history,mainPath,handleLogoutClick,loggedIn,main,mainUserData}) =>{
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const push = (path) => { window.scrollTo(0,0); history.push(path) };

    return <div  className={classes.root}>
        <CssBaseline />
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <MainLogo src={Logo} alt="Logo" onClick={()=>history.push('/')}/>
                <UserProfileContainer>
                    {/*<DefaultAvatar/>*/}
                    {/*<div>*/}
                        {/*<UserName variant={"subtitle1"}>{mainUserData.name} {mainUserData.surname}</UserName>*/}
                        {/*<UserRole variant={"subtitle2"}>Manager</UserRole>*/}
                    {/*</div>*/}
                </UserProfileContainer>
            </div>
            <CustomDivider />
            <CustomList>
                {
                    routes.map(route =>
                        <CustomListItem button key={route.path} onClick={()=>push(route.navPath || route.path)}>
                            {
                                route.icon &&
                                    <CustomListItemIcon>
                                        {React.createElement(CustomLogoutIcon(route.icon))}
                                    </CustomListItemIcon>
                            }
                            <CustomListItemText primary={route.navTitle} />
                        </CustomListItem>
                    )
                }
                <div style={{flexGrow: 1}}/>
                <CustomListItem  button onClick={handleLogoutClick}>
                    <CustomListItemIcon>{React.createElement(CustomLogoutIcon(LogoutIcon))}</CustomListItemIcon>
                    <CustomListItemText primary={strings.logout} />
                </CustomListItem >
            </CustomList>
        </Drawer>
        <main
            className={clsx(classes.content, {
                [classes.contentShift]: open,
            })}
        >
            <div style={{paddingTop:0}}/>
            {main}
        </main>
    </div>;
};

const CustomLogoutIcon = icon => styled(icon)`
    &.MuiSvgIcon-root{
        color: ${colors.mainColor}
        width: 18px;
        height: 18px;
    }
`;

const CustomListItem = styled(ListItem)`
    &.MuiButtonBase-root{
        padding-left: 36px;
        padding-right: 36px;
    }
`;

const CustomListItemIcon = styled(ListItemIcon)`
    &.MuiListItemIcon-root{
        min-width: 26px;
    }
`;

const CustomList = styled(List)`
    &.MuiList-padding{
        padding-top: 40px;
    }
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const MainTitle = styled(Typography)`
    cursor: pointer;
`;

const UserName = styled(Typography)`
    &.MuiTypography-root{
        font-weight: bold;
        color: ${colors.mainColor};
        font-size: 20px;
        line-height: 25px;
    }
`;
const UserRole = styled(Typography)`
    &.MuiTypography-root{
        color: #4F4F4F
        font-size: 14px;
    }
`;

const UserProfileContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 0 20px 0;
`;

const CustomDivider = styled.div`
    border-bottom:0.5px solid #366092;
    width:100%;
`;

const DefaultAvatar = styled(AvatarIcon)`
    &.MuiSvgIcon-root{
        height:48px;
        width: 48px;
    }
    border-radius: 50%;
    border: 2px solid ${colors.darkMainColor};
    margin-right: 8px;
`;

const CustomListItemText = styled(ListItemText)`
    .MuiTypography-root{
        font-size: 14px;
        color: ${colors.mainColor};
        //font-weight: bold;
    }
`;

const Avatar = styled.div`
    height:48px;
    width:48px;
    background-position: center;
    background-image: ${props => props.img}
`;

const MainLogo = styled.img`
    max-height: 36px;
    cursor: pointer;
`;

const LogoContainer = styled.img`
    max-height: 50px;
`;
export default withRouter(NavBar)
