export const strings = {
    appTitle: "Omint",

    //Details
    changePlanState: "Se cambiará el estado del plan",
    changeState: "Cambiar Estado",
    questions: "Preguntas",
    yes: "Si",
    no: "No",
    surname: "Apellido",
    phone: "Teléfono",
    weight: "Peso",
    height: "Altura",
    gender: "Género",
    years: "Años",
    affiliation: "Afiación",
    validTo: "Vigencia",
    children: "Hijos",
    plan: "Plan",
    beneficiarios: "Beneficiarios",
    imc: "IMC",
    observations: "Observaciones",
    noObservationsToShow: "Sin observaciones para mostrar",
    addObservationsModalTitle: "Agregar observación",
    observationDetails: "Detalle de la observación",
    observationType: "Tipo de observación",
    call: "Llamada",
    events: "Eventos",

    //Grid
    filter: "Filtrar",
    exportToExcel: "Exportar a excel",
    subscriptionsTableTitle: "Afiliaciones Pendientes",
    reportsTableTitle: "Reporte mensual",
    rowsPerPage: "Filas por página",
    of: "de",
    downloadExcel: "Descargar excel",
    dni: "DNI",
    name: "Nombre",
    state: "Estado",
    provincias: "Provincias",
    rejectReason: "Motivo rechazo",
    provincia: "Provincia",
    date: "Fecha",
    planPrice: "Precio proporcional",
    planMonthlyPrice: "Precio mensual",
    planNextMonthPrice: "Precio del mes siguiente",
    regulationStatus: "Estado desregulación",
    input: "Aportes",
    promotion: "Código de descuento",
    calls: "Llamados",
    asessorCode: "Código de asesor",
    memberNumer: "N° socio",
    afiliationDate: "Fecha de afiliacion",
    marcaEspecial: "Marca especial",
    detail: "Detalle",
    reciboDeSueldo: "Recibo de sueldo",


    //Aportes grid
    monthlyReport: 'Reporte mensual',
    nroSocio: 'N° socio',
    value: 'Valor',
    aporte: 'Aporte',
    //FILTROS
    filterModalTitle: "Filtrar afiliaciones",
    cancel: "Cancelar",
    agree: "Aceptar",
    quantity: "Cantidad",
    startDate: "Fecha desde",
    endDate: "Fecha hasta",
    invalidoComercial: "Comercial",
    invalidoMedico: "Médico",
    mail: "Mail",
    page: "Página",
    all: "Todos",
    userRole: "Rol",
    asesor: "Asesor",
    smartPlan: "Smart 2018",

    //Login
    userName: "Usuario",
    password: "Constraseña",
    logIn: "Ingresar",
    logout: "Salir",
    email: "Email",
    loginFormTitle: "Bienvenido a Portal Assistance",
    verifyField: "Verificar campo ${field}",
    enterToThePortal: "Ingresar",
    ifCannotEnterSendEmail: "Si no podés ingresar envia un email a xxx@mail.com",
    forgotPassword: "Olvidé mi contraseña",
    iWantToRegister: "Quiero registrarme",
    rememberMe: "Recordarme",

    codAsesor: "Código de asesor",
    fullname: "Nombre y apellido",
    type: 'Tipo',
    register: 'Registro',
    expiration: "Expiración",
    asesorTableTitle: "Asesores",

    //Nav
    affiliationsListNavTitle: "Afiliaciones",
    asesorBlackListNavTitle: "Bloqueo de asesores",
    reportsListNavTitle: "Aportes",
};
