import React from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";

const DeleteDialog = ({open,onClose,onSubmit,asesor}) =>
    <Dialog open={open}>
        <DialogTitle id="form-dialog-title">Borrar</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Se borrará el registo el asesor {asesor && asesor.codAsesor} de la lista
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancelar
            </Button>
            <Button onClick={()=> onSubmit(asesor).then(onClose) } color="primary">
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>;

export default DeleteDialog
