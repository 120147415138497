import React from "react";
import {routes} from "./routes";

const withAuthWrapper = (authorized,WrappedComponent) =>
    class AuthWrapper extends React.Component {
        componentDidMount(){
            if(!authorized)
                this.props.history.push(routes.login.path)
        }
        render = () => <WrappedComponent {...this.props}/>
    };

export default withAuthWrapper

