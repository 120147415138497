import React from "react";
import { Paper, Table, TableHead, TableRow, TableBody, TableCell, Button, TablePagination, TableFooter } from "@material-ui/core"
import { columns } from "../../model"
import { inputTypes } from "../../../../utils/types";
import LinkIcon from "@material-ui/icons/Link";
import { ROWS_PER_PAGE_OPTIONS } from "../../../../config";
import { strings } from "../../../../localization/strings";

const CustomTable = ({ rows, handleDetailClick, rowsPerPage, currentPage, totalPages, handleChangePage, handleChangeRowsPerPage }) =>
    <Paper>
        <div id={"table-wrapper"}>
            <Table >
                <TableHead>
                    <TableRow>
                        {
                            columns.map((column, key) =>
                                <TableCell key={key}>{column.title}</TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, key) => (
                        <TableRow key={key} className={"affiliation-row"}>
                            {
                                columns.map((column, index) =>
                                    <TableCell key={key + "-" + index}>
                                        {
                                            column.inputType === inputTypes.linkButton ?
                                                <Button variant="contained"
                                                    color="primary"
                                                    onClick={() => handleDetailClick(row[column.fieldProp])}>
                                                    <LinkIcon />
                                                </Button> :
                                                (column.format && row[column.fieldProp] && column.format(row[column.fieldProp])) ||
                                                row[column.fieldProp] || "-"
                                        }
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} ` + strings.of + ` ${count}`}
                            labelRowsPerPage={strings.rowsPerPage}
                            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            count={totalPages * rowsPerPage}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            SelectProps={{
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>

        </div>
    </Paper>;

export default CustomTable
