import {errorStrings} from "../localization/errors"

export const errors = {
    "EXPIRED_SESSION": errorStrings.expiredSession,
    "DEFAULT_EXCEPTION": errorStrings.defaultErrorMessage,
};

export const getErrorStringFromError = (error) => {
    try{

        if(error.msg)
            return error.msg;

        //es-lint-disable-next-line
        if(error.response && (error.response.status == 403 || error.response.status == 401))
            return errors.EXPIRED_SESSION;

        return errors[error.response.data] ?errors[error.response.data]:errorStrings.defaultErrorMessage;
    }catch(e){
        return errorStrings.defaultErrorMessage
    }
};