import NAME, {variantStyles} from "./constants";

export const getToastMessage = (state) => state[NAME].msg;

export const getToastTitle = (state) => state[NAME].title;

export const getActionClickHandler = (state) => state[NAME].actionClickHandler ;

export const getCancelCallback = (state) => state[NAME].cancelCallback || (()=>{});

export const getToastState = (state) => state[NAME].toastIsOpen;

export const getToastVariantStyle = (state) => state[NAME].variant || variantStyles.failure;
