import React from "react";
import {Dialog,TextField,DialogTitle,FormHelperText,DialogContent,DialogActions,Button,FormControl,Select,InputLabel,MenuItem} from "@material-ui/core";
import {strings} from "../../../../localization/strings";
import {eventNames, eventTypes} from "../../model";
import {errorStrings} from "../../../../localization/errors";
class ObservationsDialog extends React.Component{
    state={observation: "",observationType: "",error:""};
    render(){
        const {open, handleClose} = this.props;
        const {observation, observationType, error} = this.state;
        return <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{strings.addObservationsModalTitle}</DialogTitle>
            <DialogContent>
                <FormControl className={"observation-type-select"} error={error === "observationType"}>
                    <InputLabel htmlFor="age-simple">{strings.observationType}</InputLabel>
                    <Select
                        value={observationType}
                        onChange={e => this.setState({observationType: e.target.value,error:""})}
                        inputProps={{
                            name: 'observation-type',
                            id: 'observation-type',
                        }}
                    >
                        <MenuItem value=""/>
                        {
                            Object.values(eventTypes).map(id =>
                                <MenuItem key={id} value={id}>{eventNames[id]}</MenuItem>
                            )
                        }
                    </Select>
                    {error === "observationType" && <FormHelperText>{errorStrings.observationTypeError}</FormHelperText>}
                </FormControl>
                <TextField
                    error={error === "observation"}
                    helperText={error === "observation" && errorStrings.observationError}
                    margin="dense"
                    id="observation"
                    label={strings.observationDetails}
                    multiline
                    rows="4"
                    type="text"
                    fullWidth
                    value={observation}
                    onChange={e => this.setState({observation: e.target.value,error:""})}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {strings.cancel}
                </Button>
                <Button onClick={this.handleConfirmDialog} color="primary">
                    {strings.agree}
                </Button>
            </DialogActions>
        </Dialog>;
    }
    handleConfirmDialog = () => {
        const {observation, observationType} = this.state;
        if(observationType.length <= 0){
            this.setState({error: "observationType"});
            return;
        }
        if(observation.length <=0){
            this.setState({error: "observation"});
            return;
        }

        this.props.addObservation(observationType,observation);
        this.props.handleClose();
    }
}

export default ObservationsDialog