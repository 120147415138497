import React from "react"
import CustomSelect from "./select";
import CustomTextField from "./text";
import {strings} from "../../localization/strings";

const shareLinks = [
    {icon: "facebook",link:"https://www.facebook.com/CSE.SMART.SERVICE/",title:"Facebook"},
    {icon: "instagram",link:"https://www.instagram.com/cse.smart.service/",title:"Instagram"},
    {icon: "linkedin",link:"https://www.linkedin.com/in/alan-climent-56511111b",title: "Linkedin"},
    {icon: "twitter",link:"https://twitter.com/@cse89634907",title:"Twitter"},
    {icon: "whatsapp",link:"https://wa.me/5491159231641",title:"Whatsapp"}
];

const toggleFromArray = (array,itemToToggle) =>
    array.includes(itemToToggle)?
        array.filter( item => itemToToggle !== item):
        [...array,itemToToggle];

const ShareLink = ({onChange,value}) =>
    <div>
        <CustomSelect
            label={strings.shareWith}
            value={value.shareProviders}
            options={shareLinks.map(link => ({label:link.title,value:link.title}))}
            onChange={(link)=> onChange({...value,shareProviders:toggleFromArray(value.shareProviders,link)})}
            multiple={true}
            chips={true}
        />
        {
            value.shareProviders.map(share =>
                <CustomTextField
                    key={share}
                    value={value.values[share]}
                    type={"text"}
                    label={strings[share]}
                    onChange={(link)=>onChange({...value,links:{...value.links,[share]:link}})}
                />
            )
        }
    </div>;

export default ShareLink