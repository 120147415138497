import NAME from "./constants";

export const GET_AFFILIATIONS = NAME + "/GET_AFFILIATIONS";
export const GET_AFFILIATIONS_FULFILLED = NAME + "/GET_AFFILIATIONS_FULFILLED";
export const GET_AFFILIATIONS_REJECTED = NAME + "/GET_AFFILIATIONS_REJECTED";


export const RESET_APORTES = NAME + "/RESET_APORTES";

export const EXPORT_GRID = NAME + "/EXPORT_GRID";
export const EXPORT_GRID_END = NAME + "/EXPORT_GRID_END";

export const CHANGE_FILTER = NAME + "/CHANGE_FILTER";
export const CHANGE_PAGE = NAME + "/CHANGE_PAGE";
export const CHANGE_ROWS_PER_PAGE = NAME + "/CHANGE_ROWS_PER_PAGE";

