import React from "react";
import Theme from "./theme";
import Router from "../../../router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isLoggedIn, getRoles, getPermissions } from "../../login/selectors";

import { Toast } from "../../snackbar/index";
import Loader from "../../../common/loader";
import { isLoading } from "../selectors";
import { LoginPage } from "../../login/index";
import { logoutAction } from "../../login/actions";
import { RESET_AFFILIATIONS } from "../../main/actionTypes"
import { RESET_APORTES } from "../../reports/actionTypes";

class App extends React.Component {
    render() {
        const { dispatch, loggedIn, loading, roles, permissions } = this.props;

        return <Theme>
            {
                loggedIn ?
                    <Router loggedIn={loggedIn} permissions={permissions} onLogoutClick={() => {
                        dispatch(logoutAction)
                        dispatch({ type: RESET_AFFILIATIONS })
                        dispatch({ type: RESET_APORTES })
                    }} /> :
                    <LoginPage />
            }
            <Loader loading={loading} />
            <Toast />
        </Theme>
    };
}

export default connect(createStructuredSelector({
    loggedIn: isLoggedIn,
    // authorized: isAuthorized,
    loading: isLoading,
    roles: getRoles,
    permissions: getPermissions
}))(App)
