import React from "react";
import UploadIcon from "@material-ui/icons/CloudUploadOutlined";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography/Typography";
import {strings} from "../../localization/strings";

const CustomFiles = ({value,type,name,label,onChange,emptyLabel}) =>
    <FileInputContainer>
        <CustomLabel htmlFor={`file-upload-${name}`}>
            <CustomUploadIcon/>
            <PickFileText variant={"h6"}>{value? value.name:(emptyLabel||strings.pickFile)}</PickFileText>
        </CustomLabel>
        <FileInput className="input-file"
               accept="image/*"
               onChange={
                   (e)=>{
                       if(e.target.files && e.target.files[0])
                           onChange(e.target.files[0]);
                       e.target.value = null;
                   }
               }
               id={`file-upload-${name}`}
               type="file"/>
    </FileInputContainer>;

const CustomUploadIcon = styled(UploadIcon)`
    color:#828282;
    width: 24px;
`;

const PickFileText = styled(Typography)`
    &.MuiTypography-root{
        color:#828282;
        margin-left: 10px;
        text-decoration: underline;
        font-size: 16px;
    }
`;

const FileInputContainer = styled.div`
`;

const FileInput = styled.input`
    display: none;
`;

const CustomLabel = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export default CustomFiles
