import { LOGOUT, REQUEST_LOGIN, REQUEST_LOGIN_FULFILLED, REQUEST_LOGIN_REJECTED } from "./actionTypes";
import { openErrorToast } from "../snackbar";
import { logIn } from "../../shared/api";
import { setAuthInfo } from "./storeSession";


export const logInAction = (userName, password, token) => async (dispatch, getState) => {
    dispatch({ type: REQUEST_LOGIN });
    try {

        let response = await logIn({ userName, token, password });
        const { Logueado, Funcionalidades, Bearer, Roles } = response.data;
        const authInfo = Logueado && { permissions: Funcionalidades.map(func => (parseInt(func))), token: Bearer, roles: Roles };
        setAuthInfo(authInfo);
        dispatch({ type: REQUEST_LOGIN_FULFILLED, payload: authInfo });
    } catch (e) {
        dispatch({ type: REQUEST_LOGIN_REJECTED });
        dispatch(openErrorToast(e));
        throw (e)
    }
};

export const logoutAction = dispatch => dispatch({ type: LOGOUT });
