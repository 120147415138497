import NAME from "./constants";
import { filters } from "./model";

export const isLoading = (store) => store[NAME].loadingAffiliations || store[NAME].exportingGrid;
export const getFilters = (store) => store[NAME].filters;
export const getAffiliations = (store) => store[NAME].affiliations[store[NAME].currentPage] || [];
export const getTotalPages = (store) => store[NAME].totalPages;
export const getUserRole = (store) => store[NAME].userRole;
export const getRowsPerPage = (store) => store[NAME].rowsPerPage;
export const getCurrentPage = (store) => store[NAME].currentPage;

