import React from "react"
import {Paper,List,ListItem,ListItemText,ListSubheader,ListItemSecondaryAction,Typography} from "@material-ui/core/";
import {strings} from "../../../localization/strings";

const Questions = ({answers,title}) =>
    answers && answers.length>=0?
        <Paper className={"profile-container"}>
            <List
                subheader={<ListSubheader component="div">{title}</ListSubheader>}
            >
                {answers.map( (answer,key) =>
                    <ListItem key={key}>
                        <ListItemText
                            primary={(answer.pregunta && answer.pregunta.pregunta) || ""}
                            secondary={
                                answer.resultado && answer.opciones &&
                                answer.opciones.map( opt => opt.Opcion + (opt.OpcionTexto? (" - "+opt.OpcionTexto):"")).join(", ")

                            }
                        />
                        <ListItemSecondaryAction>
                            <Typography variant={"subtitle1"}>{answer.resultado? strings.yes:strings.no}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>)}
            </List>
        </Paper> :"";

export default Questions
