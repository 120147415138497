import React from "react"
import CustomSelect from "../../../../common/input/select";
import {strings} from "../../../../localization/strings";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, TextField,
} from "@material-ui/core";
import {errorStrings} from "../../../../localization/errors";

class ProfileStateDialog extends React.Component{
    state={profileStateSelected: this.props.profileState || null,observation:"",error:""};

    render(){
        const {handleClose,open,profileStates,loadingProfileStates} = this.props;
        const {profileStateSelected,error,observation} = this.state;
        return <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{strings.changePlanState}</DialogTitle>

            <DialogContent>
                <div key={"sate-select"}>
                    <CustomSelect
                        label={strings.state}
                        value={profileStateSelected || ""}
                        options={profileStates}
                        onChange={this.handleStateChange}
                        loading={loadingProfileStates}
                    />
                </div>

                <TextField
                    error={error === "observation"}
                    helperText={error === "observation" && errorStrings.observationError}
                    margin="dense"
                    id="observation"
                    label={strings.observationDetails}
                    multiline
                    rows="4"
                    type="text"
                    fullWidth
                    value={observation}
                    onChange={e => this.setState({observation: e.target.value,error:""})}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {strings.cancel}
                </Button>
                <Button onClick={this.handleConfirmDialog} color="primary">
                    {strings.agree}
                </Button>
            </DialogActions>
        </Dialog>
    }

    handleStateChange = (value) => this.setState({profileStateSelected: value});
    handleConfirmDialog = () => {
        const {profileStateSelected,observation} = this.state;
        if(!profileStateSelected){
            this.setState({error:"profileStateSelected"});
            return
        }
        if(!observation || observation.length<0){
            this.setState({error:"observation"});
            return
        }

        this.props.handleClose();
        this.props.handleConfirm(profileStateSelected,observation);
    }
}

export default ProfileStateDialog