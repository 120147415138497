import React from "react";
import Chip from "@material-ui/core/Chip/Chip";
import IntegrationDownshift from "./autosuggest";
import styled from "styled-components";

const MultipleSuggestion = ({disabled,label,value,onChange,options}) =>
    <MultipleSuggestionContainer>
        <IntegrationDownshift
            label={label}
            onChange={ (e,v) => {
                onChange({active: [...((value && value.active) || []),v.suggestion]});
            }}
            onEnter={ (label) => {
                onChange({active: [...((value && value.active) || []),{label}]})
            }}
            //value={}
            suggestions={options}
            disabled={disabled}
            //onInputChange={}
            //inputValue={}
        />
        <ChipsContainer>
            {
                value && value.active && value.active.map &&
                value.active.map( (opt,key) =>
                        <Chip
                            key={key}
                            label={opt.label}
                            onDelete={()=>onChange({active: value.active.filter( (i,k) => k!==key)})}
                        />
                    )
            }
        </ChipsContainer>
    </MultipleSuggestionContainer>;

const ChipsContainer = styled.div`
`;
const MultipleSuggestionContainer = styled.div`
`;

export default MultipleSuggestion;
