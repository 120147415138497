import React from "react"
import {Paper,List,ListItem,Avatar,ListItemText,IconButton,ListSubheader,ListItemSecondaryAction,Typography} from "@material-ui/core/";
import PhoneIcon from "@material-ui/icons/Phone";
import {strings} from "../../../../localization/strings";
import {eventIcons} from "../../model";
import {formatDate} from "../../../../utils/date";
import AddIcon from "@material-ui/icons/AddCircle";
import NextIcon from "@material-ui/icons/NavigateNext";
import PrevIcon from "@material-ui/icons/NavigateBefore";
import {EVENTS_PER_PAGE} from "../../../../config";
import Loader from "../../../../common/loader";

class Event extends React.Component{
    state={page:0};

    changePage = (page) =>{
        if(page<0 || !this.props.events || this.props.events.length/EVENTS_PER_PAGE < page) return;

        this.setState({page});
    };

    render() {
        const {events,handleOpenEventModal,title,loading} = this.props;
        const {page} = this.state;

        return <Paper className={"profile-container"}>
            {(events && events.length) > 0?
                <React.Fragment>
                    <List
                        key={"list"}
                        subheader={<ListSubheader component="div">{title}</ListSubheader>}
                    >
                        {events.slice(page*EVENTS_PER_PAGE,(page+1)*EVENTS_PER_PAGE).map( (event,index) =>
                            <ListItem button key={index}>
                                <Avatar>
                                    {eventIcons[event.tipo] || <PhoneIcon color={"primary"}/>}
                                </Avatar>
                                <ListItemText primary={event.observacion} secondary={formatDate(event.fecha,true)} />
                                <ListItemSecondaryAction>
                                    <Typography variant={"subtitle1"}>{event.operador}</Typography>
                                </ListItemSecondaryAction>
                            </ListItem>)}
                    </List>
                    {
                        events.length>5 &&
                        <div key={"pagination"} className={"paginator-container"}>
                            <Typography className={"paginator-text"} variant={"subtitle1"}>
                                {strings.page} {page+1}/{Math.ceil(events.length/EVENTS_PER_PAGE)}
                            </Typography>
                            <IconButton onClick={()=>this.changePage(page-1)}><PrevIcon/></IconButton>
                            <IconButton onClick={()=>this.changePage(page+1)}><NextIcon/></IconButton>
                        </div>
                    }
                </React.Fragment>:
                <Typography variant={"subtitle1"}>{strings.noObservationsToShow}</Typography>
            }

            {
                handleOpenEventModal &&
                <div className={"add-button-container"}>
                    <IconButton onClick={handleOpenEventModal}>
                        <AddIcon color={"primary"}/>
                    </IconButton>
                </div>
            }
            <Loader loading={loading}/>
        </Paper>
    }
}

export default Event