import React, { useState, useEffect } from "react";
import {
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Button,
    TableFooter
} from "@material-ui/core"
import { columns } from "../../model"
import { inputTypes } from "../../../../utils/types";
import LinkIcon from "@material-ui/icons/Link";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton/IconButton";
import EditDialog from "./editDialog";
import DeleteDialog from "./deleteDialog";
import styled from "styled-components";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";

const CustomTable = ({ rows, handleDetailClick, onDelete,
    onUpdate, onCreate,
    rowsPerPage, currentPage, totalPages,
    handleChangePage, handleChangeRowsPerPage, createDialogOpenAdd,
    orderBy, orderDirection, onOrderClick,
    handleCreateDialogOpen, allAsesores }) => {
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);


    useEffect(() => {
        setCreateDialogOpen(createDialogOpenAdd)
    }, [createDialogOpenAdd]);

    return <Paper>
        <div id={"table-wrapper"}>
            <Table >
                <TableHead>
                    <TableRow>
                        {
                            columns.map((column, key) =>
                                column.order ?
                                    <TableCell key={key}>
                                        <TableSortLabel
                                            active={column.fieldProp === orderBy}
                                            direction={orderDirection}
                                            onClick={() => onOrderClick(column.fieldProp, orderDirection === 'asc' ? 'desc' : 'asc')}
                                        >{column.title}
                                        </TableSortLabel>
                                    </TableCell> :
                                    <TableCell key={key}>
                                        {column.title}
                                    </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, key) => (
                        <TableRow key={key} className={"affiliation-row"}>
                            {
                                columns.map((column, index) =>
                                    <TableCell key={key + "-" + index}>
                                        {
                                            column.inputType === inputTypes.linkButton ?
                                                <Button variant="contained"
                                                    color="primary"
                                                    onClick={() => handleDetailClick(row[column.fieldProp])}>
                                                    <LinkIcon />
                                                </Button> :
                                                (column.format && row[column.fieldProp] && column.format(row[column.fieldProp])) ||
                                                row[column.fieldProp] || "-"
                                        }
                                    </TableCell>
                                )
                            }
                            <TableCell >
                                <IconButton onClick={() => setDeleteDialogOpen(row)}><DeleteIcon /></IconButton>
                                <IconButton onClick={() => {
                                    const codAsesor = allAsesores.find(filter => filter.value === row.codAsesor)

                                    const rowSelected = {
                                        ...row,
                                        codAsesor: codAsesor
                                    }
                                    setCreateDialogOpen(rowSelected)
                                }}><EditIcon /></IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
        <EditDialog
            open={createDialogOpen !== false}
            allAsesores={allAsesores}
            asesor={createDialogOpen}
            onClose={() => {
                setCreateDialogOpen(false)
                handleCreateDialogOpen(false)
            }}
            onConfirm={(v) => {
                if (v.codAsesor.value && v.Expiracion !== "Invalid date") {
                    let data;
                    if (createDialogOpen === null) {
                        data = {
                            Expiracion: v.Expiracion,
                            codAsesor: v.codAsesor.value
                        }
                    } else if (createDialogOpen.codAsesor.value) {
                        data = {
                            id: createDialogOpen.id,
                            Expiracion: v.Expiracion,
                            codAsesor: createDialogOpen.codAsesor.value
                        }
                    }
                    return (createDialogOpen === null ? onCreate(data) : onUpdate(data))
                        .then(() => {
                            setCreateDialogOpen(false);
                            handleCreateDialogOpen(false)
                        })
                }
            }
            }
        />
        <DeleteDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onSubmit={onDelete}
            asesor={deleteDialogOpen}
        />
    </Paper>
};

const AddIconContainer = styled(TableRow)` text-align: center`;

export default CustomTable
