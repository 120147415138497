import { LoginPage } from "../features/login";
import { MainPage } from "../features/main";
import { DetailsPage } from "../features/details";
import { AportesPage } from "../features/reports";

import { strings } from "../localization/strings";
import { AsesorBlacklistPage } from "../features/asesorBlacklist";
import PeopleIcon from "@material-ui/icons/People";
import ClearIcon from "@material-ui/icons/Clear";

export const routes = {
    // login: {component: LoginPage, path:"/login",requireAuth: false},
    details: { component: DetailsPage, path: "/detalles", params: "/:guid", requireAuth: true },
    asesorBlacklist: { component: AsesorBlacklistPage, path: "/blackList", requireAuth: true, navTitle: strings.asesorBlackListNavTitle, icon: ClearIcon, permissions: ['14'] },
    reports: { component: AportesPage, path: "/aportes", requireAuth: true, navTitle: strings.reportsListNavTitle, icon: PeopleIcon, permissions: ['6'] },
    main: { component: MainPage, path: "/", requireAuth: true, navTitle: strings.affiliationsListNavTitle, icon: PeopleIcon },
};

export const getRoutes = () => Object.values(routes);
export const getAuthorizedRoutes = (auth) => Object.values(routes).filter(route => !route.requireAuth || (route.requireAuth && auth));
