import React,{useEffect,useState} from "react";
import {connect} from "react-redux";
import {logInAction} from "../actions";
import {inputs} from "../model";
import {strings} from "../../../localization/strings";
import styled from "styled-components";
import * as queryString from 'query-string'
import OmintSvg from "../../../assets/images/omint.svg";
import {openErrorToast} from "../../snackbar";
import MainBackground from "../../../assets/images/back.jpg";
import Typography from "@material-ui/core/Typography/Typography";
import {isLoading} from "../selectors";
import {createStructuredSelector} from "reselect";
import CustomControlledForm from "../../../common/controlledForm";
import {colors} from "../../../config/colors";
import Grid from "@material-ui/core/Grid/Grid";
import Input from "../../../common/input";
import {inputTypes} from "../../../utils/types";
import {getLoginData, isLoginDataPresent, removeLoginData, setLoginData} from "../storeLoginData";

const LoginPage = ({dispatch,history}) => {
    const [loadingGuid,setLoadingGuid] = useState(false);
    const [rememberMe,setRememberMe] = useState(isLoginDataPresent());
    const initialData = getLoginData()||{};

    useEffect(()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const userName = urlParams.get('userName');

        if(token && userName){
            setLoadingGuid(true);
            onLogin(userName,null,token)
        }
    },[]);

    const onLogin = (values) =>
        new Promise((resolve,reject) => {
            dispatch(logInAction(values.mail,values.contrasenia))
                .then( r => {
                    if(rememberMe)
                        setLoginData({mail:values.mail});
                    else
                        removeLoginData();

                    resolve(r)
                })
                .catch( e => reject(e));
        });

    return <MainLoginContainer>
        <GridContainer container>
            <Grid item xs={0} md={6}/>
            <Grid item xs={12} md={6}>
                <FadedContainer>
                    <FormContainer>
                        <LogoContainer>
                            <MainLogo src={OmintSvg} alt={"OmintSvg"}/>
                        </LogoContainer>
                        <CustomControlledForm
                            defaultValues={initialData}
                            loading={loadingGuid}
                            disabled={loadingGuid}
                            align={"left"}
                            size={'md'}
                            showClearButton={false}
                            paper={false}
                            postInputs={
                                <PostInputsBox>
                                    <RememberMeContainer>
                                        <Input
                                            inputType={inputTypes.checks}
                                            value={[rememberMe]}
                                            options={[{value: true,label:strings.rememberMe}]}
                                            onChange={v => setRememberMe(v[0])}
                                            singleValue={true}
                                            justify={"start"}
                                        />
                                    </RememberMeContainer>
                                </PostInputsBox>
                            }
                            submitButtonLabel={strings.enterToThePortal}
                            inputs={inputs}
                            title={""}
                            onSubmit={onLogin}
                            onError={(input,validation)=>dispatch(openErrorToast((input && input.label && (validation && validation.msg? validation:{msg: strings.verifyField.replace("${field}",input.label)}))))}
                        />
                    </FormContainer>
                </FadedContainer>
            </Grid>
        </GridContainer>
    </MainLoginContainer>
};

const GridContainer = styled(Grid)`
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
`;

const PostInputsBox = styled.div`
    margin-bottom: 20px;
`;
const ForgotPasswordContainer = styled.div`
`;
const RememberMeContainer = styled.div`
    margin-top: 2px;
`;

const IfCannotEnterSendEmailText = styled(Typography)`
    &.MuiTypography-root{
        font-size: 14px;
        text-align: center;
        color: #4F4F4F;
    }
`;
const ForgotPasswordText = styled(Typography)`
    &.MuiTypography-root{
        margin-top: 11px;
        font-size: 12px;
        text-align: right;
        color: ${colors.mainColor};
        text-decoration: underline;
        font-weight: bold;        
        cursor: pointer;
    }
`;
const WantToRegisterText = styled(Typography)`
    &.MuiTypography-root{
        margin-top: 32px;
        font-size: 16px;
        text-align: center;
        color: ${colors.mainColor};
        text-decoration: underline;
        font-weight: 600;
        cursor: pointer;
    }
`;

const Footer = styled(Typography)`
    &.MuiTypography-root{
        margin-bottom: 15px;
    }
`;
const LogoContainer = styled.div`
    text-align: center;
    width: 100%;
`;

const MainLogo = styled.img`
    width: 100%;
    max-width: 302px;
    height: auto;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction:column;
    justify-content: center;
    flex-grow: 1;
    max-width:100%;
    width: 327px;
`;

const FadedContainer = styled.div`
    background: rgba(255,255,255,0.95);
    padding: 0 88px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    height: 100%;
`;

const MainLoginContainer = styled.div`
    height:100vh;
    width:100%;
    position: relative;
    background-size: cover;
    background-image: ${()=> 'url('+MainBackground+')'}
`;

export default connect(createStructuredSelector({
    loading: isLoading
}))(LoginPage)
