import React from "react";
import {Snackbar,IconButton,SnackbarContent}from "@material-ui/core";
import { createStructuredSelector } from 'reselect';
import {
    getActionClickHandler,
    getCancelCallback, getToastMessage,
    getToastState, getToastTitle,getToastVariantStyle
} from "../selectors";
import {connect} from "react-redux";
import {closeToast} from "../actions";
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from "@material-ui/icons/ArrowBack";

const CustomSnackbar = ({open,onActionClick,onClose,message,variantStyle,title,dispatch}) =>
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={()=>dispatch(closeToast)}
        ContentProps={{
            'aria-describedby': 'message-id',
        }}
    >
        <SnackbarContent
            aria-describedby="client-snackbar"
            style={variantStyle.content}
            message={<span id="toast-message">
                {variantStyle.icon}
                {message}
            </span>}
            action={[
                ...(onActionClick?[<IconButton
                    key="action"
                    aria-label="Action"
                    color="inherit"
                    onClick={()=>{onActionClick();dispatch(closeToast);}}
                >
                    <ArrowBack/>
                </IconButton>]:[]),


                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={()=>{dispatch(closeToast);}}
                >
                    <CloseIcon/>
                </IconButton>
            ]}
        />
    </Snackbar>;


export default connect(
    createStructuredSelector({
        open: getToastState,
        onActionClick: getActionClickHandler,
        onClose: getCancelCallback,
        message: getToastMessage,
        title: getToastTitle,
        variantStyle: getToastVariantStyle
    })
)(CustomSnackbar);