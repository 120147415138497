import React from "react";
import {strings} from "../../localization/strings";
import Switch from "@material-ui/core/Switch/Switch";
import Typography from "@material-ui/core/Typography";

const YesOrNoSwitch = ({value,className,label,disabled,onChange}) =>
    <div className={"switch-container"}>
        <Typography variant={"subtitle1"}>
            {label}
        </Typography>
        {strings.no}
        <Switch className={(className||"")}
                checked={!!value}
                onClick={e => onChange(!value)}
                inputProps={{ disabled:disabled }}
        />
        {strings.yes}
    </div>;

export default YesOrNoSwitch