import React from "react";
import TextField from "@material-ui/core/TextField";
import CheckIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import {colors} from "../../config/colors";

const CustomTextField = ({InputLabelProps,className,onKeyPress,rowsMax,InputProps,error,fullWidth,helperText,multiline,rows,disabled,autoComplete,name,value,type,label,onChange,onKeyDown,onBlur,valid,showState,variant,autoFocus,required,loading}) =>
    <StyledTextField
        multiline={multiline}
        rowsMax={rowsMax}
        rows={rows}
        helperText={helperText}
        name={name}
        disabled={disabled || loading}
        autoComplete={autoComplete}
        required={required}
        fullWidth={fullWidth}
        className={"custom-input "+(!valid && value && showState?"error-input":"")+(className||"")}
        error={error || (!valid && showState)}
        label={label}
        type={type}
        autoFocus={autoFocus}
        value={value}
        InputLabelProps={ InputLabelProps?InputLabelProps:{
            shrink: type==="date"?true:undefined,
        }}
        onBlur={onBlur || (()=>{})}
        onChange={(e)=>onChange && onChange(e.target.value)}
        variant={variant}
        onKeyDown={onKeyDown || (()=>{})}
        onKeyPress={onKeyPress || (()=>{})}
        InputProps={
            loading?
                {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="Loading">
                                <CircularProgress size={20}/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }:
                InputProps?
                    InputProps:
                    value && showState ? {
                        endAdornment:
                            valid?
                                <CheckIcon className={"check-icon"} />:
                                <WarningIcon className={"warning-icon"} />
                    }:{}
        }
    />;

const StyledTextField = styled(TextField)`
    &.custom-input label{
        color: ${colors.lightMainColor};
        font-size: 16px;
        opacity: 0.6;
    }
    .MuiInput-underline:before{
        border-color: ${colors.mainColor};
    }
    fieldset {
        border-color: ${colors.mainColor};
    }
`;

export default CustomTextField
