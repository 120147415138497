import React, { useState } from "react"
import styled, { css } from "styled-components";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { inputTypes } from "../../../../utils/types";
import { strings } from "../../../../localization/strings";
import Input from "../../../../common/input";
import Button from "@material-ui/core/Button/Button";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import moment from "moment";

export const filters = {
    codAsesor: { name: "codAsesor", inputType: inputTypes.select, type: "number", label: strings.codAsesor },
    expiration: { name: "expiration", inputType: inputTypes.date, type: "date", label: strings.date }
};

const DialogForm = ({ prevValues, onClose, onConfirm, allAsesores }) => {
    const [values, setValues] = useState({
        codAsesor: prevValues && prevValues.codAsesor || "",
        Expiracion: prevValues && moment(prevValues.Expiracion).format("YYYY-MM-DD") || ""
    });

    return <DialogWrapper>
        <DialogContentStyled>
            <InputContainer>
                {console.log('values', values)}
                <Input
                    {...filters.codAsesor}
                    value={(values && values.codAsesor) || ""}
                    onChange={codAsesor => { setValues({ ...values, codAsesor }) }}
                    fullWidth
                    inputType={inputTypes.autosuggest}
                    options={allAsesores}
                    withLabelValue
                />
            </InputContainer>
            <InputContainer>
                <Input
                    {...filters.expiration}
                    value={(values && values.Expiracion) || ""}
                    fullWidth
                    onChange={Expiracion =>
                        setValues({ ...values, Expiracion })
                    }
                />
            </InputContainer>
        </DialogContentStyled>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancelar
            </Button>
            <Button onClick={() => onConfirm(values)} color="primary">
                Confirmar
            </Button>
        </DialogActions>
    </DialogWrapper>
};

const InputContainer = styled.div`
    margin: 15px 0;
`;
const DialogWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DialogContentStyled = styled(DialogContent)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    & > div {
        width: 200px;
    }

    & > div > div {
        display: flex;
        flex-direction: column;
    }

    & > div > div > p{
        margin-bottom: 30px;
    }
`;
const EditDialog = ({ open, asesor, onConfirm, onClose, allAsesores }) =>
    <EditDialogContainer>
        <StyledDialog open={open}>
            <StyledTitle id="alert-dialog-title">{"Asesor"}</StyledTitle>
            {
                open &&
                <DialogForm
                    prevValues={asesor}
                    onConfirm={onConfirm}
                    onClose={onClose}
                    allAsesores={allAsesores}
                />
            }
        </StyledDialog>
    </EditDialogContainer>;

const EditDialogContainer = styled.div`
`;

const StyledDialog = styled(Dialog)`
.MuiDialog-paperFullWidth {
    width: calc(100% - 64px);
}

.MuiDialog-paper{
    overflow-y: auto;
    margin: 32px;
    position: relative;
}

.MuiDialog-paperWidthMd {
    max-width: 960px;
}
.MuiDialog-paperScrollPaper {
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    width: calc(100% - 64px);
}

`;

const StyledTitle = styled(DialogTitle)`
    
`;

export default EditDialog
