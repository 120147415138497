import React from "react"
import {Typography, Grid, Paper, Button} from "@material-ui/core";
import {personalInfoFields,infoList} from "../../model";
import Loader from "../../../../common/loader/index";
import AccountIcon from "@material-ui/icons/AccountCircle";
import ProfileStateDialog from "./dialog";
import {strings} from "../../../../localization/strings";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class Profile extends React.Component {
    state={profileStateDialogOpen:false};
    render(){
        const {
            personalInfo,
            affiliation,
            djs,
            loading,
            beneficiarios,
            changeBeneficiario,
            beneficiarioIndex,
            profileStates,
            loadingProfileStates,
            changePlanState,
            plan,
            userPermissions,
            canChangeAffiliationStatus
        } = this.props;
        const {profileStateDialogOpen} = this.state;
        return  <Paper className={"profile-container"}>
                    {
                        personalInfo && affiliation && djs ?
                            <React.Fragment>
                                {/*<Typography variant={"h6"} key={"name"}>*/}
                                    {/*{personalInfo[personalInfoFields.name.prop]}, {personalInfo[personalInfoFields.surname.prop]}*/}
                                {/*</Typography>*/}
                                <Select
                                    style={{width: "100%"}}
                                    value={beneficiarioIndex}
                                    onChange={e=>changeBeneficiario(e.target.value)}
                                    inputProps={{
                                        name: 'name',
                                        id: 'name-simple',
                                    }}
                                >
                                    {
                                        (beneficiarios ||[])
                                            .map( (beneficiario, index) =>
                                                <MenuItem value={index}>
                                                    {beneficiario[personalInfoFields.name.prop]}, {beneficiario[personalInfoFields.surname.prop]}
                                                </MenuItem>
                                            )
                                    }
                                </Select>
                                {/*<hr key={"Separator"}/>*/}
                                {
                                    this.getDataList(personalInfo,affiliation,djs,plan,userPermissions)
                                }
                                {
                                    canChangeAffiliationStatus &&
                                    <div className={"centered-container"}>
                                        <Button variant="outlined"
                                                color="primary"
                                                onClick={this.handleOpenDialog}>
                                            {strings.changeState}
                                        </Button>
                                    </div>
                                }
                            </React.Fragment>
                            :<Loader loading={loading}/>
                    }
                    <ProfileStateDialog
                        handleClose={this.handleCloseDialog}
                        open={profileStateDialogOpen}
                        profileStates={profileStates}
                        profileState={affiliation && affiliation.EstadoBackOffice}
                        loadingProfileStates={loadingProfileStates}
                        handleConfirm={changePlanState}
                    />
                </Paper>;
    }

    handleOpenDialog = () => this.setState({profileStateDialogOpen:true});
    handleCloseDialog = () => this.setState({profileStateDialogOpen:false});
    getDataList = (personalInfo,affiliation,djs,plan,userPermissions) => {
        let fields = {...affiliation,...djs,plan,...personalInfo};
        return (infoList || []).map(info => {
            let value = fields[info.prop];
            if(info.format)
                value = info.format(value,userPermissions,fields);

            if(!value)
                return <div/>;

            return  <Grid key={"item-"+info.label} container className={"info-container"} >
                <Grid item xs={6}>
                    {info.icon || <AccountIcon color={"primary"}/>}
                    <Typography variant={"subtitle1"} className={"profile-prop"}>
                        <b>{info.label}</b>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant={"subtitle2"} className={"profile-info"}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        })
    }
}

export default Profile
