import React from "react";
import CustomTable from "./table/table";
import {
    changeFilterAction,
    changeRowsPerPageAction, exportGridAction,
    getAffiliationsAction,
    getAsesoresAction, getProvinciasAction,
    getStatesAction
} from "../actions";
import { connect } from "react-redux";
import Loader from "../../../common/loader";
import {
    getAffiliations, getAsyncFilters, getCurrentPage,
    getFilters, getRowsPerPage,
    getTotalPages,
    getUserRole,
    isLoading
} from "../selectors";
import { createStructuredSelector } from "reselect";
import Filter from "./table/options";
import { routes } from "../../../router/routes";
import { ROWS_PER_PAGE_DEFAULT_OPTION } from "../../../config";
import { hasPermission } from "../../login/selectors";
import { permissions } from "../../../shared/permissions";
import { orderProps } from "../model";

class MainPage extends React.Component {

    state = { orderBy: "", orderDirection: "" };

    componentDidMount() {
        this.props.dispatch(getAffiliationsAction(0));
        this.props.dispatch(getStatesAction);

        if (this.props.canFilterByAsesor)
            this.props.dispatch(getAsesoresAction);

        this.props.dispatch(getProvinciasAction);
    }

    render() {
        const { orderDirection, orderBy } = this.state;
        const { loading, filters, affiliations, totalPages,
            //userRole,
            currentPage, rowsPerPage, asyncFilters, canFilterByAsesor } = this.props;
        return <div id={"main-page"}>
            <Loader loading={loading} />
            <Filter
                handleFilterChange={this.handleFilterChange}
                filters={filters}
                canFilterByAsesor={canFilterByAsesor}
                asyncFilters={asyncFilters}
                handleDownloadExcelClick={this.handleDownloadExcelClick}
            />
            <CustomTable
                rows={affiliations}
                handleDetailClick={this.handleDetailClick}
                orderBy={orderBy}
                orderDirection={orderDirection}
                onOrderClick={this.onOrderClick}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                totalPages={totalPages}
                handleChangePage={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
        </div>
    };

    onOrderClick = (orderBy, orderDirection) => {
        this.setState({ orderBy, orderDirection });
        this.props.dispatch(getAffiliationsAction(0, orderProps[orderBy], orderDirection));
    };

    handleFilterChange = (newValues) => {
        this.props.dispatch(changeFilterAction(newValues));
        this.props.dispatch(getAffiliationsAction(0));
    };
    handleDetailClick = (guid) => this.props.history.push(routes.details.path + "/" + guid);
    handleChangePage = (e, newPage) => {
        if (!e)
            return;
        let page = 1;
        try {
            page = newPage;
            //page = parseInt(newPage,10);
        } catch (e) {
            //Si por alguna razon material me devuelve algo que no puede parsearse a int vuelvo a la primera página
            page = 1;
        }
        this.props.dispatch(getAffiliationsAction(page));
    };
    handleChangeRowsPerPage = (e) => {
        try {
            this.props.dispatch(changeRowsPerPageAction(parseInt(e.target.value)));
        } catch (e) {
            //Si por alguna razon material me devuelve algo que no puede parsearse a int muestro los default
            this.props.dispatch(changeRowsPerPageAction(ROWS_PER_PAGE_DEFAULT_OPTION));
        }
    };

    handleDownloadExcelClick = () => this.props.dispatch(exportGridAction);
}

export default connect(createStructuredSelector({
    loading: isLoading,
    filters: getFilters,
    affiliations: getAffiliations,
    totalPages: getTotalPages,
    userRole: getUserRole,
    asyncFilters: getAsyncFilters,
    rowsPerPage: getRowsPerPage,
    currentPage: getCurrentPage,
    canFilterByAsesor: hasPermission(permissions.asesoresFilter)
}))(MainPage)
