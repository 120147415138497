import {
    HIDE_LOADER, SHOW_LOADER, WEB_APP_ONLINE,
    //WINDOWS_AUTH,
    // WINDOWS_AUTH_FULFILLED,
    // WINDOWS_AUTH_REJECTED
} from "./actionTypes";

const initialState = {
    online: true,
    authorizeResponse: null,
    authorizing: false,
    loading: false
};

export default function reducer(state=initialState, action){

    switch (action.type){
        case WEB_APP_ONLINE: return {...state,online: action.payload};
        // case WINDOWS_AUTH: return {...state,authorizing:true};
        // case WINDOWS_AUTH_FULFILLED: return {...state,authorizing:false,authorizeResponse: action.payload};
        // case WINDOWS_AUTH_REJECTED: return {...state,authorizing:false};
        case SHOW_LOADER: return {...state,loading:true};
        case HIDE_LOADER: return {...state,loading:false};
       default: return state
    }
}