import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import { strings } from "../../localization/strings";
import styled from "styled-components";
import { colors } from "../../config/colors";

const Loader = () => <CircularProgress size={20} />;

const CustomSelect = ({ showEmpty, label, variant, value, options, onChange, loading, readOnly, multiple, chips, disabled, getOptions, fullWidth }) =>
    <CustomFormControl className={"custom-input"} fullWidth={fullWidth}>
        <CustomInputLabel shrink htmlFor={(label || "") + "-select"} >
            {label}
        </CustomInputLabel>

        <Select
            variant={variant}
            multiple={!!multiple}
            value={value === undefined || value === "" ? (multiple ? [] : "") : value}
            onChange={(e) => {
                onChange && onChange(e.target.value)
            }}
            inputProps={{
                name: `${label}-select`,
                id: `${label}-select`,
                readOnly: !!readOnly,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            renderValue={chips && (selected => (
                <div className={"chip-container"}>
                    {Array.isArray(selected) ?
                        selected.map(value => (
                            <Chip key={value} label={value} />
                        )) :
                        <Chip label={selected} />
                    }
                </div>
            ))}
            IconComponent={loading ? Loader : undefined}
            disabled={disabled || loading}
        >
            {
                showEmpty &&
                <MenuItem value={""}>{strings.empty}</MenuItem>
            }
            {
                (options ? (getOptions ? getOptions(options) : options) : []).map(option =>
                    <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                )
            }
        </Select>
    </CustomFormControl>;

const CustomInputLabel = styled(InputLabel)`
    &.MuiFormLabel-root{
        color: ${colors.mainColor};
        opacity: 0.6
    }
`;

const CustomFormControl = styled(FormControl)`
    .MuiInput-underline:before{
        border-color: ${colors.mainColor};
    }
    ${({ fullWidth }) => fullWidth && `width: 100%;`}

`;

export default CustomSelect
