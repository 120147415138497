import { strings } from "../../localization/strings";
import { inputTypes } from "../../utils/types";
import moment from "moment";
import React from "react";

export const columns = [
    { title: strings.dni, fieldProp: "DNI", index: 0 },
    { title: strings.memberNumer, fieldProp: "NumeroSocio", index: 1 },
    { title: strings.date, fieldProp: "TimeStamp", index: 2, format: (value) => (moment(value).isValid() && moment(value).format("DD/MM/YYYY")) || value },
    { title: strings.value, fieldProp: "Valor", index: 3 },
    { title: strings.aporte, fieldProp: "AporteMensual", index: 4 },
    { title: strings.startDate, fieldProp: "FechaVigencia", index: 5, format: (value) => (moment(value).isValid() && moment(value).format("DD/MM/YYYY")) || value }
];

export const filters = {
    startDate: { name: "desde", inputType: inputTypes.date, type: "date", label: strings.startDate },
    endDate: { name: "hasta", inputType: inputTypes.date, type: "date", label: strings.endDate },
    dni: { name: "dni", inputType: inputTypes.text, label: strings.dni, type: "number" }
};

export const modalFilters = [
    filters.startDate,
    filters.endDate,
    filters.dni
];