import {OPEN_TOAST, CLOSE_TOAST} from "./actionTypes";
import {variantStyles} from "./constants";
import {errors, getErrorStringFromError} from "../../shared/errors";
import {logoutAction} from "../login/actions";

export const openSuccessToast = (msg) =>
    (dispatch) => dispatch({type: OPEN_TOAST,payload:{msg: msg,variant: variantStyles.success}});

export const openErrorToast = (e,actionClickHandler,cancelCallback) =>
    (dispatch) => {
        const errorMessage =  getErrorStringFromError(e);
        if( errorMessage === errors.EXPIRED_SESSION)
            dispatch(logoutAction);
        dispatch({type: OPEN_TOAST,payload:{msg:errorMessage ,actionClickHandler,cancelCallback,variant: variantStyles.failure} })
    };

export const closeToast = (dispatch) => dispatch({type: CLOSE_TOAST});